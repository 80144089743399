export const imageSchema = {
  title: 'Image',
  type: 'object',
  required: ['order'],
  properties: {
    disabled: { type: 'boolean', title: 'Disabled' },
    order: { type: 'number', title: 'Order' },
    url: { type: 'string', title: 'Url' },
    style: { type: 'string', title: 'Style' },
    resizeMode: { type: 'string', title: 'resize Mode' },
  },
};
