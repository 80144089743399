import { Avatar, FormLabel, Tooltip } from "@mui/material"
import { DataProvider, ListComponent } from "./GenericListComponent"
import { ImageWidget, SelectWidget } from "./components/CustomWidgets"

import { BusinessAdsConfig } from "./BusinessAdsConfig"

const data = {
  getListLocation(id) {
    return ["businesses", id, "ads"]
  },
  EDIT_TITLE: "Edit Ad",
  ADD_TITLE: "Add Ad",
  LOADING_MESSAGE: "Loading ads...",
  TITLE: "Business Ads",
  IMAGES_BUCKET: "adsImages",

  columnsProperties: [
    { hidden: true, name: "id", label: "Id" },
    { required: false, name: "disabled", label: "Disabled", type: "boolean" },

    { required: true, name: "expirationDate", label: "Expiration Date" },
    { required: true, name: "name", label: "Name" },
    { required: true, name: "order", label: "Order" },
    {
      hidden: false,
      type: "array",
      required: false,
      name: "images",
      label: "Images",
      options: {
        customBodyRender: (value) => {
          const arr = Array.isArray(value) ? value : [value]
          return (
            <div className="flex flex-row gap-1 flex-wrap">
              {arr.map((x, i) => {
                return (
                  <a key={i} href={x?.link} target="_blank" rel="noreferrer">
                    <FormLabel>{x?.title}</FormLabel>
                    <Tooltip title={x?.title}>
                      <Avatar variant="square" src={x?.image} alt={`${x?.title}-Image`} />
                    </Tooltip>
                  </a>
                )
              })}
            </div>
          )
        },
      },
      items: {
        type: "object",
        properties: {
          title: {
            type: "string",
            title: "Title",
          },
          link: {
            type: "string",
            title: "Link",
          },
          image: {
            type: "string",
            title: "Image",
          },
          linkTarget: {
            type: "string",
            title: "Link",
            enum: ["_self", "_blank", "_top"],
          },
          viewLinkOnPopupIframe: {
            type: "boolean",
            title: "View Link On Popup Iframe",
          },
        },
      },
    },
    { required: true, name: "description", label: "Description" },
    { required: true, name: "language", label: "Language" },
    { required: true, name: "country", label: "Country" },
    { required: false, name: "state", label: "State" },
    { required: false, name: "city", label: "City" },
    { required: false, name: "street", label: "Street" },
    { required: false, name: "county", label: "County" },
    { required: false, name: "zipCode", label: "Zip Code" },
    { required: false, name: "generateSocialMediaAds", label: "Generate Social Media Ads" },
    { required: false, name: "socialMediaAdsInfo", label: "Social Media Ads Info" },
  ],

  uiSchema: {
    images: {
      items: {
        image: {
          "ui:widget": ImageWidget,
        },
      },
    },
    expirationDate: {
      "ui:widget": "datetime",
    },

    description: {
      "ui:widget": "textarea",
    },
  },

  async imageProcessor(fromData, handleUpload, id) {
    if (fromData.images?.length > 0) {
      const imagesURLs = await Promise.all(
        fromData.images
          .map(({ image, ...rest }) => {
            return handleUpload(id, this.IMAGES_BUCKET, image, rest)
          })
          .filter((x) => !!x),
      )
      fromData.images = imagesURLs
    }

    return fromData
  },
  widgets: { SelectWidget },
}

export const BusinessAdsComponent = ({ businessId }) => {
  return (
    <div>
      <BusinessAdsConfig businessId={businessId} />
      <DataProvider {...data} mainId={businessId}>
        <ListComponent />
      </DataProvider>
    </div>
  )
}
