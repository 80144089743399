export const contentSchema = {
  title: "Content",
  type: "object",
  required: ["name", "phone", "email"],
  properties: {
    disabled: { type: "boolean", title: "Disabled" },
    name: { type: "string", title: "Name" },
    profileImageUrl: { type: "image", title: "Profile Image Url" },
    phone: { type: "string", title: "Phone" },
    email: { type: "string", title: "Email" },
    address: { type: "string", title: "Address" },
  },
}