import {
  AppBar,
  Autocomplete,
  Avatar,
  Box,
  Button,
  CircularProgress,
  Container,
  FormLabel,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material"
import React, { useEffect, useState } from "react"
import { useLocation, useParams } from "react-router-dom"

import EditSponsor from "./EditSponsor"
import ServiceForm from "./ServiceForm"
import SwipeableViews from "react-swipeable-views"

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  )
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  }
}

const SponsorTabs = ({ onSuccess }) => {
  const { id } = useParams()
  const { state } = useLocation()
  const [data, setData] = useState()
  const [value, setValue] = useState(0)
  const theme = useTheme()

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleChangeIndex = (index) => {
    setValue(index)
  }
  function reload() {
    //   getData({ path: `/businesses/${id}`, setData })
  }
  useEffect(() => {
    if (state?.id) {
      setData(state)
    } else {
      reload()
    }
  }, [id, state])

  return (
    <Box sx={{ bgcolor: "background.paper", width: "80vw" }}>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label="Sponsor Detail" {...a11yProps(0)} />
          <Tab label="Sponsor Services" {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          <EditSponsor />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <ServiceForm />
        </TabPanel>
      </SwipeableViews>
    </Box>
  )
}
export default SponsorTabs
