import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import React, { useState } from "react"

import DraggableInput from "./DraggableInput"

const AdminPanel = () => {
  const [components, setComponents] = useState([])
  const [availableComponents, setAvailableComponents] = useState([
    {
      id: "100",
      label: "Testing 123",
    },
  ])

  const onDragEnd = (result) => {
    if (!result.destination) return

    const items = Array.from(components)
    if (result.source.droppableId === "selector") {
      // Adding new component
      const newComponent = { id: Date.now().toString(), type: result.draggableId }
      items.splice(result.destination.index, 0, newComponent)
    } else {
      // Reordering existing components
      const [reorderedItem] = items.splice(result.source.index, 1)
      items.splice(result.destination.index, 0, reorderedItem)
    }

    setComponents(items)
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="flex">
        <Droppable droppableId="selector" isDropDisabled={true}>
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps} className="p-4 bg-gray-100">
              <h3 className="mb-4">Add Components</h3>
              {availableComponents.map((component, index) => (
                <Draggable key={component.id} draggableId={component.id} index={index}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className="mb-2 p-2 bg-white border rounded"
                    >
                      {component.label}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>

        <Droppable droppableId="components">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps} className="flex-1">
              {components.map((component, index) => (
                <DraggableInput
                  key={component.id}
                  id={component.id}
                  index={index}
                  component={component}
                  updateComponent={(updatedComponent) =>
                    setComponents(
                      components.map((c) => (c.id === updatedComponent.id ? updatedComponent : c)),
                    )
                  }
                  deleteComponent={(id) => setComponents(components.filter((c) => c.id !== id))}
                />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
    </DragDropContext>
  )
}

export default AdminPanel
