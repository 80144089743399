import {
  AppBar,
  Autocomplete,
  Avatar,
  Box,
  Button,
  CircularProgress,
  Container,
  FormLabel,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material"
import React, { useEffect, useState } from "react"
import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  or,
  query,
  startAfter,
  updateDoc,
  where,
} from "firebase/firestore"
import { useLocation, useParams } from "react-router-dom"

import Form from "@rjsf/mui"
import { ImageWidget } from "./CustomWidgets"
import SwipeableViews from "react-swipeable-views"
import { db } from "../firebase"
import validator from "@rjsf/validator-ajv8"

const EditSponsor = () => {
  const { id } = useParams()
  const [sponsorData, setSponsorData] = useState({})

  useEffect(() => {
    const fetchSponsorData = async () => {
      const docRef = doc(db, "sponsors", id)
      const docSnap = await getDoc(docRef)
      if (docSnap.exists()) {
        const data = docSnap.data()
        // Ensure all fields are properly initialized
        setSponsorData({
          name: data.name || "",
          iconUrl: data.iconUrl || "",
          image: data.image || "",
          location: data.location || { city: "", country: "DO", province: "" },
          likesCount: data.likesCount || 0,
          shareCount: data.shareCount || 0,
          disabled: data.disabled || false,
          userIds: data.userIds || [],
        })
      } else {
        console.log("No such document!")
      }
    }

    fetchSponsorData()
  }, [id])

  const handleSave = async (formData) => {
    const docRef = doc(db, "sponsors", id)

    // formData = await Service.imageProcessor(formData, handleUpload, id, mainId)

    await updateDoc(docRef, formData.formData)
    // Optionally redirect back to the list or show a success message
  }

  const schema = {
    title: "Edit Sponsor",
    type: "object",
    required: ["name", "location", "iconUrl", "image", "userIds"],
    properties: {
      name: { type: "string", title: "Name" },
      iconUrl: { type: "string", title: "Icon URL" },
      image: { type: "string", title: "Image URL" },
      location: {
        type: "object",
        title: "Location",
        properties: {
          country: { type: "string", title: "Country" },
          city: { type: "string", title: "City" },
          province: { type: "string", title: "Province" },
        },
      },
      likesCount: { type: "integer", title: "Likes Count" },
      shareCount: { type: "integer", title: "Share Count" },
      disabled: { type: "boolean", title: "Disabled" },
      userIds: {
        type: "array",
        title: "Assigned Users",
        items: {
          type: "string",
        },
        uniqueItems: true,
      },
    },
  }

  const AutocompleteWidget = (props) => {
    const { value = "", onChange, id } = props // Ensure value is always a string
    console.log("AutocompleteWidget value:", value)

    const [users, setUsers] = useState([])
    const [userSearch, setUserSearch] = useState("")
    const [loadingUsers, setLoadingUsers] = useState(false)
    const [lastVisibleUser, setLastVisibleUser] = useState(null)

    const fetchUsers = async (searchTerm = "", startAfterUser = null) => {
      setLoadingUsers(true)
      const usersRef = collection(db, "users")

      let userQuery = query(
        usersRef,
        where("name", ">=", searchTerm || ""), // Only fetch users where 'name' field exists and matches the search
        where("name", "<=", (searchTerm || "") + "\uf8ff"),
        limit(100),
      )

      if (startAfterUser) {
        userQuery = query(userQuery, startAfter(startAfterUser))
      }

      const querySnapshot = await getDocs(userQuery)
      const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1]
      setLastVisibleUser(lastVisible)

      const userList = querySnapshot.docs
        .map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))
        .filter((user) => user.name) // Ensure the user has a name

      setUsers(userList)
      setLoadingUsers(false)
    }

    useEffect(() => {
      fetchUsers()
    }, [])

    const handleUserSearchChange = (event, value) => {
      setUserSearch(value)
      fetchUsers(value)
    }

    return (
      <Autocomplete
        options={users}
        value={users.find((user) => user.id === value) || null} // Find the selected user
        getOptionLabel={(option) => option.name || option.id || "Unknown User"}
        onChange={(event, newValue) => {
          onChange(newValue ? newValue.id : "") // Set the selected user's ID
        }}
        inputValue={userSearch}
        onInputChange={handleUserSearchChange}
        loading={loadingUsers}
        renderOption={(props, option) => (
          <li {...props} key={option.id}>
            {option.name || option.id || "Unknown User"}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select User"
            variant="outlined"
            fullWidth
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loadingUsers ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    )
  }

  const uiSchema = {
    iconUrl: {
      "ui:widget": "image",
    },
    image: {
      "ui:widget": "image",
    },
    userIds: {
      items: {
        "ui:widget": "autocomplete",
      },
    },
    location: {
      city: {
        "ui:placeholder": "Enter city name",
      },
    },
    likesCount: {
      "ui:readonly": true,
    },
    shareCount: {
      "ui:readonly": true,
    },
  }

  const widgets = {
    autocomplete: AutocompleteWidget,
    image: ImageWidget,
  }

  if (!sponsorData) {
    return <div>Loading...</div>
  }

  return (
    <Container>
      {/* <h2>Edit Sponsor</h2> */}
      <Form
        schema={schema}
        uiSchema={uiSchema}
        formData={sponsorData}
        onSubmit={handleSave}
        widgets={widgets}
        validator={validator}
      >
        <Button type="submit" variant="contained" color="primary">
          Save Changes
        </Button>
      </Form>
    </Container>
  )
}

export default EditSponsor
