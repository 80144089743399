import {
  Box,
  Button,
  FormControl,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material"
import { CityLocationSelector, CountryLocationSelector, StateLocationSelector } from "../LocationSelector"
import React, { useState } from "react"

import Cropper from "react-easy-crop"
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker"
import Dropzone from "react-dropzone"
import { PopupService } from "./PopupService"
import dayjs from "dayjs"

const DateTimeWidget = ({ value, onChange, label, required, ...rest }) => {
  // console.log("DateTimeWidget--->", { value, rest })

  return (
    <DateTimePicker
      {...rest}
      label={!label ? null : `${label} ${required ? "*" : ""}`}
      slotProps={{ textField: { variant: "outlined" } }}
      value={value ? dayjs(value) : null}
      onChange={(newValue) => {
        const val = newValue ? newValue.valueOf() : ""
        return onChange && onChange(val)
      }}
    />
  )
}

const LanguageDropdownWidget = ({ value, onChange, label, required, ...rest }) => {
  // console.log("DateTimeWidget--rest->", rest)
  // const { t } = useTranslation()
  function t(a) {
    return a
  }
  return (
    <Box sx={{ minWidth: 120, marginTop: 2 }}>
      <FormControl fullWidth>
        <InputLabel id="language-selector-label">{`${label} ${required ? "*" : ""}`}</InputLabel>
        <Select
          className="flex flex-column gap-10"
          label={`${label} ${required ? "*" : ""}`}
          labelId="language-selector-label"
          value={value}
          onChange={({ target }) => {
            // console.log(target.value)
            onChange(target.value)
          }}
        >
          <MenuItem value="en">{t("English")}</MenuItem>
          <MenuItem value="es">{t("Español")}</MenuItem>
        </Select>
      </FormControl>
    </Box>
  )
}

const SelectWidget = ({ options, value, onChange, label, ...rest }) => {
  // console.log('SelectWidget--rest->',label, rest)
  return !options.enumOptions?.length ? null : (
    <TextField
      select
      value={value || ""}
      label={label}
      onChange={(event) => onChange(event.target.value)}
    >
      {options.enumOptions.map(({ value, label }) => (
        <MenuItem key={value} value={value}>
          {label}
        </MenuItem>
      )) || null}
    </TextField>
  )
}

const ImageWidget = ({ value, onChange, label, required, id, setFormData, ...rest }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  console.log("ImageWidget---rest--->", rest)
  const handleDeleteImage = () => {
    onChange("")
  }

  const handleImageDrop = (acceptedFiles) => {
    const file = acceptedFiles[0]
    const reader = new FileReader()
    reader.onload = () => {
      onChange(reader.result)
      // setFormData && setFormData(getHash(reader.result), 'LOCAL');
    }
    reader.readAsDataURL(file)
  }

  // Function to handle image editing (cropping)
  const handleEditImage = () => {
    PopupService.pushPopup(
      <div
        style={{
          width: 600,
          height: 600,
        }}
      >
        <Cropper
          image={value}
          crop={crop}
          zoom={zoom}
          aspect={1}
          onCropChange={setCrop}
          onZoomChange={setZoom}
          // onCropComplete={onCropComplete}
        />
        {/* <Button onClick={()=>{setZoom(x => x++)}} endIcon={<div>+</div>}>{zoom}</Button> */}
        {/* <Button onClick={()=>{setZoom(x => x--)}} endIcon={<div>-</div>}>{zoom}</Button> */}
      </div>,
    )
  }

  //   const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
  //     setCrop(croppedAreaPixels)
  //   }, [])

  return (
    <div>
      <FormLabel>
        {label} {!!required && <span>*</span>}
      </FormLabel>
      <Dropzone onDrop={handleImageDrop} accept="image/*">
        {({ getRootProps, getInputProps }) => (
          <section>
            <Box className="bg-slate-200" m={3} p={3} {...getRootProps()}>
              <input {...getInputProps()} />
              <p>{`Drag 'n' drop an image here, or click to select an image`}</p>
            </Box>
          </section>
        )}
      </Dropzone>

      <RenderImage
        value={value}
        handleDeleteImage={handleDeleteImage}
        handleEditImage={handleEditImage}
      />
    </div>
  )
}

const RenderImage = ({ value, handleDeleteImage, handleEditImage, label }) => {
  if (!value) {
    return null
  }

  let src = value

  if (value.hasOwnProperty("_isLocal")) {
    src = value._value
  }

  return (
    <div className="flex flex-col justify-center gap-3">
      <img
        className="w-50"
        style={{
          height: "25vh",
          objectFit: "contain",
          padding: 5,
        }}
        src={src}
        alt={label}
      />
      <div className="flex flex-row gap-3 justify-between">
        <Button variant="contained" color="warning" onClick={handleDeleteImage}>
          Delete
        </Button>
        <Button variant="contained" color="primary" onClick={handleEditImage}>
          Edit
        </Button>
      </div>
    </div>
  )
}

const AutocompleteWidget = (props) => {
  const { value = [], onChange } = props

  const handleChange = (event, newValue) => {
    if (Array.isArray(newValue)) {
      onChange(newValue.map((user) => user.id))
    } else if (newValue) {
      onChange([newValue.id])
    } else {
      onChange([])
    }
  }
  return null
  // return (
  //   <Autocomplete
  //     multiple
  //     options={users}
  //     value={users.filter((user) => value.includes(user.id))}
  //     getOptionLabel={(option) => option.name || option.id || "Unknown User"}
  //     onChange={handleChange}
  //     inputValue={userSearch}
  //     onInputChange={handleUserSearchChange}
  //     loading={loadingUsers}
  //     renderOption={(props, option) => (
  //       <li {...props} key={option.id}>
  //         {option.name || option.id || "Unknown User"}
  //       </li>
  //     )}
  //     renderInput={(params) => (
  //       <TextField
  //         {...params}
  //         label="Search Users"
  //         variant="outlined"
  //         fullWidth
  //         InputProps={{
  //           ...params.InputProps,
  //           endAdornment: (
  //             <>
  //               {loadingUsers ? <CircularProgress color="inherit" size={20} /> : null}
  //               {params.InputProps.endAdornment}
  //             </>
  //           ),
  //         }}
  //       />
  //     )}
  //   />
  // )
}

const MultilineWidget = (props) => {
  const { id, value, required, label, onChange, onBlur, onFocus } = props

  return (
    <TextField
      id={id}
      label={label}
      multiline
      rows={4}
      value={value || ""}
      required={required}
      variant="outlined"
      fullWidth
      onChange={(e) => onChange(e.target.value)}
      onBlur={onBlur && ((e) => onBlur(id, e.target.value))}
      onFocus={onFocus && ((e) => onFocus(id, e.target.value))}
    />
  )
}

const CountrySelectWidget = ({ value, onChange, label, ...rest }) => {
  // console.log("SelectWidget--rest->", label, rest)
  return <CountryLocationSelector value={value} onChange={(n, v) => onChange(v)} />
}

const StateSelectWidget = ({ value, onChange, label, ...rest }) => {
  // console.log("SelectWidget--rest->", label, rest)
  return <StateLocationSelector value={value} onChange={(n, v) => onChange(v)} />
}

const CitySelectWidget = ({ value, onChange, label, ...rest }) => {
  // console.log("SelectWidget--rest->", label, rest)
  return <CityLocationSelector value={value} onChange={(n, v) => onChange(v)} />
}



export {
  CitySelectWidget,
  StateSelectWidget,
  CountrySelectWidget,
  DateTimeWidget,
  SelectWidget,
  ImageWidget,
  AutocompleteWidget,
  LanguageDropdownWidget,
  MultilineWidget,
}
