import { Avatar, FormLabel, Tooltip } from "@mui/material"

export function SampleImageRenderer(props) {
  const link = props?.link
  return (value) => {
    const arr = Array.isArray(value) ? value : [value]
    const Root = link
      ? ({ children, ...rest }) => (
          <a {...rest} href={value} target="_blank" >
            {children}
          </a>
        )
      : ({ children, ...rest }) => <div {...rest}>{children}</div>
    return (
      <Root className="flex flex-row gap-1 flex-wrap">
        {arr.map((x, i) => {
          if (typeof x === "string") {
            return (
              <Tooltip key={i} title={"Sample Image Icon"}>
                <Avatar variant="square" src={x} alt={`Sample-Image-Icon`} />
              </Tooltip>
            )
          }
          return (
            <a key={i} href={x?.link} target="_blank" rel="noreferrer">
              <FormLabel>{x?.title}</FormLabel>
              <Tooltip title={x?.title}>
                <Avatar variant="square" src={x?.image} alt={`${x?.title}-Image`} />
              </Tooltip>
            </a>
          )
        })}
      </Root>
    )
  }
}
