import { CircularProgress, FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import React, { createContext, useContext, useEffect, useState } from "react"
import { doc, getDoc } from "firebase/firestore"

import { PopupService } from "./components/PopupService"
import { db } from "./firebase"
import { getCountryInfo } from "./utils"
import { motion } from "framer-motion"
import { useTranslation } from "react-i18next"

const LocationSelector = ({
  onChange,
  userSelectedCountry,
  userSelectedState,
  userSelectedCity,
}) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(true)
  const [locations, setLocations] = useState({})
  const [countries, setCountries] = useState([])
  const [selectedCountry, setSelectedCountry] = useState(userSelectedCountry || "")
  const [selectedState, setSelectedState] = useState(userSelectedState || "")
  const [selectedCity, setSelectedCity] = useState(userSelectedCity || "")
  const [states, setStates] = useState([])
  const [cities, setCities] = useState([])

  useEffect(() => {
    setSelectedCountry(userSelectedCountry || "US")

    setSelectedState(userSelectedState || "")

    setSelectedCity(userSelectedCity || "")

    if (userSelectedCountry && locations?.[userSelectedCountry]) {
      setStates(userSelectedCountry ? Object.keys(locations[userSelectedCountry]).sort() : [])
    }

    if (
      userSelectedCountry &&
      userSelectedState &&
      locations?.[userSelectedCountry]?.[userSelectedState]
    ) {
      setCities(userSelectedState ? locations[userSelectedCountry][userSelectedState].sort() : [])
    }
  }, [userSelectedCountry, userSelectedState, userSelectedCity, locations])

  useEffect(() => {
    const fetchLocations = async () => {
      setLoading(true)
      try {
        const docRef = doc(db, "app-configurations/availableLocations")
        const docSnap = await getDoc(docRef)
        if (docSnap.exists()) {
          const data = docSnap.data()
          setLocations(data)
          setCountries(Object.keys(data).sort())
        }
      } catch (error) {
        console.error("Error fetching locations:", error)
      } finally {
        setLoading(false)
      }
    }

    fetchLocations()
  }, [])

  const handleCountryChange = (event, child) => {
    const country = event.target.value
    setSelectedCountry(country)
    setSelectedState("")
    setSelectedCity("")
    setStates(country ? Object.keys(locations[country]).sort() : [])
    setCities([])

    onChange("country", country)
  }

  const handleStateChange = (event, child) => {
    const state = event.target.value
    setSelectedState(state)
    setSelectedCity("")
    setCities(state ? locations[selectedCountry][state].sort() : [])
    onChange("state", state)
  }

  const handleCityChange = (event, child) => {
    const city = event.target.value
    setSelectedCity(city)
    onChange("city", city)
  }

  return (
    <div className="p-4">
      {loading ? (
        <CircularProgress />
      ) : (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <FormControl fullWidth className="mb-4">
            <InputLabel>{t("Select Country")}</InputLabel>
            <Select
              value={selectedCountry}
              onChange={handleCountryChange}
              displayEmpty
              renderValue={(value) =>
                value ? (
                  <div className="flex items-center gap-1">
                    <div>{getCountryInfo(value).emoji}</div>
                    <div>{t("" + value)}</div>
                  </div>
                ) : (
                  <span>{t("Select Country")}</span>
                )
              }
            >
              {countries.map((country) => (
                <MenuItem key={country} value={country}>
                  <div className="flex items-center gap-1">
                    <span> {getCountryInfo(country).emoji}</span>
                    <span> {t(country)}</span>
                  </div>
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth className="mb-4" disabled={!selectedCountry}>
            <InputLabel>{t("Select State")}</InputLabel>
            <Select value={selectedState} onChange={handleStateChange} displayEmpty>
              <MenuItem value="">{t("Select State")}</MenuItem>
              {states.map((state) => (
                <MenuItem key={state} value={state}>
                  {state}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth disabled={!selectedState}>
            <InputLabel>{t("Select City")}</InputLabel>
            <Select value={selectedCity} onChange={handleCityChange} displayEmpty>
              <MenuItem value="">{t("Select City")}</MenuItem>
              {cities.map((city) => (
                <MenuItem key={city} value={city}>
                  {city}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </motion.div>
      )}
    </div>
  )
}

export default LocationSelector

const LocationContext = createContext({
  countries: [],
  cities: [],
  states: [],
  country: "US",
  city: "",
  state: "",
  loading: false,
  setCity: null,
  setCountry: null,
  setState: null,
})

export const useLocation = () => {
  return useContext(LocationContext)
}

export const LocationProvider = (props) => {
  const { children } = props
  const [availableLocations, setAvailableLocations] = useState()
  const [loading, setLoading] = useState()
  const [countries, setCountries] = useState()
  const [states, setStates] = useState()
  const [cities, setCities] = useState()
  const [country, setCountry] = useState("US")
  const [state, setState] = useState()
  const [city, setCity] = useState()

  useEffect(() => {
    const fetchLocations = async () => {
      setLoading(true)
      try {
        const docRef = doc(db, "app-configurations/availableLocations")
        const docSnap = await getDoc(docRef)
        if (docSnap.exists()) {
          const data = docSnap.data()
          setAvailableLocations(data)
        }
      } catch (error) {
        PopupService.pushPopup(error.message)
        console.error("Error fetching locations:", error)
      } finally {
        setLoading(false)
      }
    }

    fetchLocations()
  }, [])

  useEffect(() => {
    if (availableLocations) {
      setCountries(["", ...Object.keys(availableLocations).sort()])
    }
  }, [availableLocations])

  useEffect(() => {
    if (availableLocations && country) {
      setStates(
        availableLocations?.[country]
          ? ["", ...Object.keys(availableLocations[country]).sort()]
          : [],
      )
    }
  }, [availableLocations, country])

  useEffect(() => {
    if (availableLocations && country && state) {
      setCities(
        availableLocations?.[country]?.[state]
          ? ["", ...availableLocations[country][state].sort()]
          : [],
      )
    }
  }, [availableLocations, country, state])

  return (
    <LocationContext.Provider
      value={{
        countries,
        cities,
        states,
        country,
        city,
        state,
        loading,
        setCity,
        setCountry,
        setState,
        setCountries,
        setStates,
        setCities,
      }}
    >
      {loading ? null : children}
    </LocationContext.Provider>
  )
}

export const CountryLocationSelector = ({ value, onChange }) => {
  const { t } = useTranslation()
  const { countries, country, loading, setCountry, setState, setCity, setCities, setStates } =
    useLocation()

  useEffect(() => {
    if (typeof value !== "undefined") {
      setCountry(value)
    }
  }, [value])

  const handleCountryChange = (event, child) => {
    const country = event.target.value
    setCountry(country)
    setState("")
    setCity("")

    setCities([])
    setStates([])

    onChange("country", country)
  }

  return (
    <div className="p-4">
      {loading ? (
        <CircularProgress />
      ) : (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <FormControl fullWidth className="mb-4">
            <InputLabel>{t("Select Country")}</InputLabel>
            <Select
              value={country || ""}
              onChange={handleCountryChange}
              displayEmpty
              renderValue={(value) =>
                value ? (
                  <div className="flex items-center gap-1">
                    <div>{getCountryInfo(value)?.emoji || ""}</div>
                    <div>{t("" + value || "Any")}</div>
                  </div>
                ) : (
                  <span>{t("Select Country")}</span>
                )
              }
            >
              {!countries
                ? null
                : countries.map((country) => (
                    <MenuItem key={country} value={country}>
                      <div className="flex items-center gap-1">
                        <span> {getCountryInfo(country)?.emoji || ""}</span>
                        <span> {t(country || "Any")}</span>
                      </div>
                    </MenuItem>
                  ))}
            </Select>
          </FormControl>
        </motion.div>
      )}
    </div>
  )
}


export const StateLocationSelector = ({ value, onChange }) => {
  const { t } = useTranslation()
  const { states, state, loading, setState } = useLocation()

  useEffect(() => {
    if (typeof value !== "undefined") {
      setState(value)
    }
  }, [value])

  useEffect(() => {
    if (state !== value) {
      onChange("state", state)
    }
  }, [state])

  const handleChange = (event, child) => {
    const city = event.target.value
    setState(city)

    onChange("state", state)
  }

  return (
    <div className="p-4">
      {loading ? (
        <CircularProgress />
      ) : (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <FormControl fullWidth className="mb-4" disabled={!states?.length}>
            <InputLabel>{t("Select State")}</InputLabel>
            <Select
              value={state || ""}
              onChange={handleChange}
              displayEmpty
              renderValue={(value) =>
                value ? (
                  <div className="flex items-center gap-1">
                    <div>{t("" + value || "Any")}</div>
                  </div>
                ) : (
                  <span>{t("Select State")}</span>
                )
              }
            >
              {!states
                ? null
                : states.map((val) => (
                    <MenuItem key={val} value={val}>
                      <div className="flex items-center gap-1">
                        <span> {t(val || "Any")}</span>
                      </div>
                    </MenuItem>
                  ))}
            </Select>
          </FormControl>
        </motion.div>
      )}
    </div>
  )
}

export const CityLocationSelector = ({ value, onChange }) => {
  const { t } = useTranslation()
  const { cities, city, loading, setCity } = useLocation()
  useEffect(() => {
    if (typeof value !== "undefined") {
      setCity(value)
    }
  }, [value])

  useEffect(() => {
    if (city !== value) {
      onChange("city", city)
    }
  }, [city])

  const handleChange = (event, child) => {
    const city = event.target.value
    setCity(city)

    onChange("city", city)
  }

  return (
    <div className="p-4">
      {loading ? (
        <CircularProgress />
      ) : (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <FormControl fullWidth className="mb-4" disabled={!cities?.length}>
            <InputLabel>{t("Select City")}</InputLabel>
            <Select
              value={city || ""}
              onChange={handleChange}
              displayEmpty
              renderValue={(value) =>
                value ? (
                  <div className="flex items-center gap-1">
                    <div>{t("" + value || "Any")}</div>
                  </div>
                ) : (
                  <span>{t("Select City")}</span>
                )
              }
            >
              {!cities
                ? null
                : cities.map((val) => (
                    <MenuItem key={val} value={val}>
                      <div className="flex items-center gap-1">
                        <span> {t(val || "Any")}</span>
                      </div>
                    </MenuItem>
                  ))}
            </Select>
          </FormControl>
        </motion.div>
      )}
    </div>
  )
}
