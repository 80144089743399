import { TableCell, TableRow } from '@mui/material';
import { deleteField, doc, updateDoc, writeBatch } from 'firebase/firestore';

import { DataTable } from '../core/CreateEditOptions/DataTable';
import { SingleObject } from '../core/SingleObject';
import { contentSchema } from './contentSchema';
import { db } from '../../firebase';
import { disabledColumnItem } from '../core/disabledColumnItem';
import { editColumn } from '../core/CreateEditOptions/editColumn';
import reduce from 'lodash.reduce';
import update from '../core/CreateEditOptions/update';

export const options = ({ path, data, onEditSuccess, setLoading, onSuccess }) => {
  return {
    filterType: 'checkbox',
    selectableRows: 'single',
    expandableRows: true,
    renderExpandableRow: (rowData, rowMeta) => {
      console.log(rowData, rowMeta);

      const obj = data[rowMeta.dataIndex];
      const content = reduce(
        obj,
        (prev, curr, key) => {
          if (key !== 'disabled' && key !== 'key') {
            const type = typeof obj[key];
            const value = type === 'string' ? obj[key] : JSON.stringify(obj[key]);
            prev.push({
              key,
              value,
              __type: type
            });
          }
          return prev;
        },
        [],
      );
      const id = obj.id;
      return (
        <>
          <TableRow>
            <TableCell colSpan={rowData.length}>
              <DataTable
                onAddSuccess={async ({ result, close }) => {
                  const { key, value } = result.formData;
                  await update({
                    dataKeyValue: key,
                    persistKey: false,
                    path,
                    setLoading,
                    key: obj.key,
                    data: { [key]: value },
                  });
                  onSuccess && onSuccess({ result, close });
                  // getData({setData, path: PATH, getMap});
                  // close();
                }}
                columns={[
                  editColumn({
                    dataRows: obj,
                    // imagePropName,
                    path: path,
                    schema: contentSchema,
                    // setLoading,
                    onEditSuccess: response => {

                      const { key, value } = response?.result?.formData || {};
                      const type = typeof obj[key];

                      if (!key) {
                        return console.error('Invalid values to update.', {
                          key,
                          value,
                        });
                      }
                      update({
                        dataKeyValue: key,
                        persistKey: false,
                        path,
                        setLoading,
                        key: obj.key,
                        data: { [key]: type === 'object' ? JSON.parse(value) : value },
                      });
                      response.close();
                      onEditSuccess &&
                        onEditSuccess({ ...response, id, isCollection: false });
                    },
                    column2object: columns => {
                      const value = typeof columns[2] === 'string' ? columns[2] : JSON.stringify(columns[2]);
                      const key = columns[1];
                      return {
                        key,
                        value,
                      };
                    },
                  }),
                  'key',
                  'value',
                ]}
                title={'Content'}
                schema={contentSchema}
                data={content}
                id={id}
                path={path}
              />
            </TableCell>
          </TableRow>
        </>
      );
    },
    onRowsDelete: onRowDelete({ path, data, onSuccess }),
  };
};

const onRowDelete = ({ path, data, onSuccess }) => {
  return async (rowsDeleted, rowData, newTableData) => {
    console.log({ rowsDeleted, rowData, newTableData });

    if (typeof path === 'string') {
      path = path.split('/');
    }
    const cityRef = doc(db, ...path);
    rowsDeleted.data.forEach(async element => {
      const row = data[element.dataIndex];
      const key = row ? row.key : '';
      const laRef = doc(db, ...path);
      await updateDoc(cityRef, {
        [key]: deleteField(),
      });
    });

    onSuccess && onSuccess(data);

    return false;
  };
};
