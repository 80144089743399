import { Button, Typography } from "@mui/material"
import React, { useEffect } from "react"
import { auth, db } from "../firebase"
import { doc, onSnapshot, query } from "firebase/firestore"
import { formatCash, formatNumber } from "../utils"

import LoginPage from "./Login"
import { PopupService } from "./PopupService"
import { useAuthState } from "react-firebase-hooks/auth"

export const UserPoints = function UserPoints(props) {
  const {
    style,
    isNoLink,
    size,
    textPosition,
    textStyle,
    iconColor,
    iconStyle,
    showMinDigits,
    precision,
    complete,
    variant = "contained",
    className,
  } = props

  const $styles = { ...$container, ...style }
  const $iconStyles = { ...$icon, ...iconStyle }
  const info = {}
  const { points, isLoggedIn, setPoints } = info
  const [user] = useAuthState(auth)

  useEffect(() => {
    if (!isLoggedIn) {
      return
    }
    const id = user?.uid

    const querySnapshot = doc(db, "users", id)
    return onSnapshot(querySnapshot, {
      next: (snap) => {
        setPoints(snap?.data()?.points || 0)
      },
      error: (data) => {
        console.log("USERS-->", data)
      },
    })
  }, [user])

  return (
    <Button
      variant={variant}
      style={$styles}
      className={`flex flex-row gap-1 p-1 ${className}`}
      startIcon={
        <img
          src={require("../logo.svg")}
          width={size || 20}
          style={$iconStyles}
          color={iconColor}
          alt="winfacil coin"
        />
      }
      // style={{ backgroundColor: colors.transparent }}
      onClick={() => {
        PopupService.setPopup(<LoginPage />)
      }}
    >
      <span className="truncate">
        {complete ? formatNumber(points) : formatCash(Math.floor(points), showMinDigits, precision)}
      </span>
    </Button>
  )
}

const $container = {
  justifyContent: "center",
  flexDirection: "row",
  marginRight: 10,
}

const $text = {
  fontSize: 14,
  marginTop: 5,
}

const $icon = {
  marginLeft: 1,
}
