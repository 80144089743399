import {
  addDoc,
  arrayRemove,
  arrayUnion,
  collection,
  doc,
  getDoc,
  getDocs,
  setDoc,
  updateDoc,
} from "firebase/firestore"
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react"
import { db, functions, storage } from "./firebase"
import { generateUUID, getFileExtension, getSchema, isBase64Image } from "./utils"
import { getDownloadURL, ref, uploadBytes } from "firebase/storage"
import { useNavigate, useParams } from "react-router-dom"

import { Add as AddIcon } from "@mui/icons-material"
import { Button } from "@mui/material"
import { DateTimeField } from "@mui/x-date-pickers"
import { DateTimeWidget } from "./components/CustomWidgets"
import Form from "@rjsf/material-ui"
import LoadingScreen from "react-loading-screen"
import { LoadingService } from "./Loading"
import MUIDataTable from "mui-datatables"
import { PopupService } from "./components/PopupService"
import dayjs from "dayjs"
import { httpsCallable } from "firebase/functions"
import validator from "@rjsf/validator-ajv8"

export const deleteCollectionAndImages = httpsCallable(functions, "deleteCollectionAndImages")

const deleteAllContent = async (documentIds, mainId, Service) => {
  try {
    LoadingService.setLoading(true)

    await deleteCollectionAndImages({
      bucketPath: Service.IMAGES_BUCKET,
      collectionPath: Service.getListLocation(mainId).join("/"),
      documentIds,
    })
    console.log("Collection and images deleted successfully.")
  } catch (error) {
    const msg = "Error deleting collection and images"
    LoadingService.setLoading(true, msg, true)
    console.error(msg, error)
    PopupService.setPopup(`${msg}\n${error.message}`)
  } finally {
    LoadingService.setLoading(false)
  }
}

const Context = createContext(null)

export const useData = () => {
  return useContext(Context)
}

export const DataProvider = ({
  children,
  getListLocation,
  TITLE,
  LOADING_MESSAGE,
  IMAGES_BUCKET,
  columnsProperties,
  uiSchema,
  imageProcessor,
  EDIT_TITLE,
  ADD_TITLE,
  onRowClick,
  mainId,
  format,
  ...rest
}) => {
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [columns, setColumns] = useState([])
  const [options, setOptions] = useState({})

  const navigate = useNavigate()

  const Service = useMemo(
    () => ({
      getListLocation,
      TITLE,
      LOADING_MESSAGE,
      IMAGES_BUCKET,
      EDIT_TITLE,
      ADD_TITLE,
      columnsProperties,
      uiSchema,
      imageProcessor,
      onRowClick,
      ...rest,
    }),
    // do not fix
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      ADD_TITLE,
      EDIT_TITLE,
      IMAGES_BUCKET,
      LOADING_MESSAGE,
      TITLE,
      columnsProperties,
      getListLocation,
      imageProcessor,
      uiSchema,
      onRowClick,
    ],
  )

  const reload = useCallback(async () => {
    try {
      setLoading(true)
      const collectionInfo = Service.getListLocation(mainId)
      let list = []
      if (Array.isArray(collectionInfo)) {
        const querySnapshot = await getDocs(collection(db, ...collectionInfo))
        list = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
      } else {
        /*
         subCollectionDoc: "ads",
      path: ["businesses", id],
      startsWith: "config-",
      propHoldingSubCollection: 'ads-config'
        */
        const docRef = doc(db, ...collectionInfo.path)

        const docSnap = await getDoc(docRef)
        if (docSnap.exists()) {
          const rawData = { ...docSnap.data(), id: docSnap.id }

          list = rawData[collectionInfo.propHoldingSubCollection]
        }
      }

      setList(list)
    } catch (error) {
      console.log(`Get ${Service.TITLE} list error`, error)
      PopupService.setPopup(error.message)
    } finally {
      setLoading(false)
    }
  }, [Service, mainId])
  // const [reload] = useState(reloadFunc)

  useEffect(() => {
    reload && reload()
  }, [reload])

  useEffect(() => {
    setColumns([
      ...Service.columnsProperties.map((x) => {
        if (x.uiType === "datetime2") {
          return {
            ...x,
            options: {
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <DateTimeField
                    readOnly
                    variant="standard"
                    fullWidth
                    style={{ width: 220 }}
                    // label="Format with meridiem"
                    defaultValue={dayjs(value)}
                    format="LLL"
                  />
                )
                // return <DateTimeWidget  value={value} />
              },
            },
          }
        }
        return x
      }),
      {
        name: "edit",
        label: "Edit",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Button
                variant="contained"
                color="primary"
                onClick={(e) => {
                  e.stopPropagation()
                  PopupService.setPopup(
                    <AddEditComponent
                      Service={Service}
                      mainId={mainId}
                      format={format}
                      data={list[tableMeta.rowIndex]}
                      onAdded={() => {
                        reload && reload()
                        PopupService.setPopup(null)
                      }}
                    />,
                  )
                }}
              >
                Edit
              </Button>
            )
          },
        },
      },
    ])

    setOptions({
      // responsive: "simple",
      onRowsDelete: (e, m) => {
        // eslint-disable-next-line no-restricted-globals
        const result = confirm(
          "Are you sure you want to delete the selected items and all it's data",
        )
        if (result) {
          ;(async function () {
            const ids = e.data.map((x) => list?.[x.dataIndex]?.id)
            await deleteAllContent(ids, mainId, Service)
            reload && reload()
          })()
        }
        return false
      },
      onRowClick: Service.onRowClick?.(list, navigate),
    })
  }, [Service, mainId, list, reload, navigate])

  Service.setData = setList

  useEffect(() => {
    Service.getData = () => {
      return list
    }
  }, [Service, list])

  return (
    <Context.Provider value={{ list, loading, columns, options, reload, mainId, Service, format }}>
      {children}
    </Context.Provider>
  )
}

export const ListComponent = ({ onSuccess, format }) => {
  const { list, loading, columns, options, reload, mainId, Service } = useData()
  return (
    <LoadingScreen
      loading={loading}
      bgColor="#f1f1f1"
      spinnerColor="#9ee5f8"
      textColor="#676767"
      logoSrc="/logo512.png"
      text={Service.LOADING_MESSAGE}
    >
      <div className="m-3">
        <Button
          variant="contained"
          color="primary"
          onClick={(e) => {
            e.stopPropagation()
            PopupService.setPopup(
              <AddEditComponent
                Service={Service}
                mainId={mainId}
                format={format}
                onAdded={() => {
                  reload && reload()
                  onSuccess && onSuccess()
                }}
              />,
            )
          }}
        >
          <AddIcon />
        </Button>
      </div>
      {loading ? null : (
        <MUIDataTable
          responsive="simple"
          title={Service.TITLE}
          data={list}
          columns={columns}
          options={options}
        />
      )}
    </LoadingScreen>
  )
}

export const AddEditComponent = ({ close, onAdded, data, mainId, Service, onAdd, format }) => {
  const [schema, setSchema] = useState({})

  const [formData, setFormData] = useState(data)

  useEffect(() => {
    const cols = Service.columnsProperties.filter((x) => !x.hidden)
    if (!cols?.length) {
      return
    }

    setSchema(getSchema(data ? Service.EDIT_TITLE : Service.ADD_TITLE, cols))
  }, [Service.ADD_TITLE, Service.EDIT_TITLE, Service.columnsProperties, data])

  const handleUpload = async (id, fieldName, image, imageInfo, imagePropName) => {
    const isBase64 = isBase64Image(image)
    if (!isBase64) {
      return imageInfo
        ? {
            ...imageInfo,
            image,
          }
        : image
    }

    const uid = generateUUID()
    const storageRef = ref(
      storage,
      `${Service.IMAGES_BUCKET}/${id}/${fieldName}-${uid}-${Date.now()}.${getFileExtension(image)}`,
    )

    const response = await fetch(image)
    const blob = await response.blob()
    await uploadBytes(storageRef, blob)

    const downloadURL = await getDownloadURL(storageRef)
    return imageInfo
      ? {
          ...imageInfo,
          image: downloadURL,
        }
      : downloadURL
  }

  function removeUndefinedFields(obj) {
    if (obj === null || typeof obj !== "object") {
      return obj // Return non-objects as is (e.g., numbers, strings, etc.)
    }

    if (Array.isArray(obj)) {
      // Handle arrays by recursively cleaning each element
      const cleanedArray = obj
        .map((item) => removeUndefinedFields(item))
        .filter((item) => item !== undefined)
      return cleanedArray.length > 0 ? cleanedArray : undefined
    }

    const cleanedObj = {}
    Object.keys(obj).forEach((key) => {
      const value = obj[key]

      // Recursively clean nested objects
      const cleanedValue = removeUndefinedFields(value)

      if (cleanedValue !== undefined) {
        cleanedObj[key] = cleanedValue
      }
    })

    // If the cleaned object has no properties, return undefined to remove it
    return Object.keys(cleanedObj).length > 0 ? cleanedObj : undefined
  }

  const handleSubmit = async (result) => {
    LoadingService.setLoading(true)
    try {
      // console.log("onSubmit", result)
      let formData = removeUndefinedFields(result.formData)

      if (format) {
        const dt = format(formData)
        if (dt) {
          formData = dt
        }
      }

      if (onAdd) {
        return onAdd(formData)
      }

      let id = data?.id

      const collectionInfo = Service.getListLocation(mainId)

      if (Array.isArray(collectionInfo)) {
        if (!data) {
          const docRef = await addDoc(collection(db, ...collectionInfo), formData)

          id = docRef.id
        }

        formData = await Service.imageProcessor(formData, handleUpload, id, mainId)

        await updateDoc(doc(db, ...collectionInfo, id), { id, ...formData })
      } else {
      }
      if (typeof close === "function") {
        close()
      }

      onAdded && onAdded()
    } catch (error) {
      PopupService.setPopup(error.message)
    } finally {
      LoadingService.setLoading(false)
    }
  }

  return (
    <Form
      formData={formData}
      schema={schema}
      uiSchema={Service.uiSchema}
      validator={validator}
      onChange={(data) => setFormData(data.formData)}
      onSubmit={handleSubmit}
      widgets={Service.widgets}
    />
  )
}

export async function addSubcollection(
  docPath,
  subcollectionPropertyName,
  subcollectionName,
  subcollectionDocId,
  data,
) {
  const docRef = doc(db, docPath)

  // Add your subcollection creation logic here
  await addDocumentToSubcollection(docPath, subcollectionName, subcollectionDocId, data)

  // Update the parent document with the subcollection name
  await updateDoc(docRef, {
    [subcollectionPropertyName]: arrayUnion(subcollectionName),
  })
}

export async function removeSubcollectionProperty(
  docPath,
  subcollectionPropertyName,
  subcollectionName,
) {
  const docRef = doc(db, docPath)

  // Update the parent document with the subcollection name
  await updateDoc(docRef, {
    [subcollectionPropertyName]: arrayRemove(subcollectionName),
  })
}

async function addDocumentToSubcollection(docPath, subcollectionName, subcollectionDocId, data) {
  const subcollectionRef = collection(doc(db, docPath), subcollectionName)
  await setDoc(doc(subcollectionRef, subcollectionDocId), data)
}

export async function getSubcollectionData(docPath, subcollectionName, subcollectionDocId) {
  const subcollectionRef = collection(doc(db, docPath), subcollectionName)
  const docRef = await getDoc(doc(subcollectionRef, subcollectionDocId))
  const data = docRef.data()
  return data
}
