import reduce from 'lodash.reduce';
const getMap = ({item, path}) => {
  if (!item) {
    return [];
  }

  item = reduce(
    item,
    (result, value, key) => {
      result[key] = typeof result[key] === 'undefined' ? '' : result[key];
      return result;
    },
    item,
  );

  let arr = [
    {
      path: path || '',
      key: '',
      ...item,
    },
  ];
  return arr;
};
export default getMap;
