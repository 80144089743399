import { Box, CircularProgress, Container } from "@mui/material"
import React, { useState } from "react"

import EditProfile from "./EditProfile"
import ViewProfile from "./ViewProfile"
import { auth } from "../firebase"
import { useAuthState } from "react-firebase-hooks/auth"
import { useTranslation } from "react-i18next"

// src/Profile.tsx

const Profile = () => {
  const { t } = useTranslation()
  const [user, loading] = useAuthState(auth)

  const [isEditing, setIsEditing] = useState(false)

  const handleEdit = () => {
    setIsEditing(true)
  }

  const handleSave = () => {
    setIsEditing(false)
  }

  const handleCancel = () => {
    setIsEditing(false)
  }

  if (loading) {
    return (
      <Box
        sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}
      >
        <CircularProgress />
      </Box>
    )
  }

  if (!user) {
    return <div>{t("No user logged in")}</div>
  }

  return (
    <Container maxWidth="sm">
      <ViewProfile onEdit={handleEdit} />
      {/* {isEditing ? (
        <EditProfile onSave={handleSave} onCancel={handleCancel} />
      ) : (
        <ViewProfile onEdit={handleEdit} />
      )} */}
    </Container>
  )
}

export default Profile
