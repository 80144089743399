export const schema = ({title}) => {
  return {
    title: title,
    type: 'object',
    required: [],
    properties: {
      disabled: {type: 'boolean', title: 'Disabled'},
      order: {type: 'number', title: 'Order'},
      key: {type: 'string', title: 'Key'},
      textContent: {type: 'string', title: 'Text Content'},
      image: {type: 'string', title: 'Image Url'},
    },
  };
};
