import {collection, addDoc} from 'firebase/firestore';
import reduce from 'lodash.reduce';
import { db } from '../../../firebase';

const add = async ({key, data, path, setLoading}) => {
  try {
    setLoading && setLoading(true);
    data = reduce(
      data,
      (result, value, key) => {
        result[key] = typeof result[key] === 'undefined' ? '' : result[key];
        return result;
      },
      data,
    );

    if (typeof path === 'string') {
      path = path.split('/');
    }
    const docRef = await addDoc(
      collection(db, ...path),
      key
        ? {
            [key]: data,
          }
        : data,
    );
    console.log('Document written with ID: ', docRef.id);
  } catch (e) {
    console.error('Error adding document: ', e);
  } finally {
    setLoading && setLoading(false);
  }
};

export default add;
