import { Avatar, Box, Button, Card, CardContent, CircularProgress, Typography } from "@mui/material"
import React, { useEffect, useState } from "react"
import { auth, db } from "../firebase"
import { collection, doc, getDoc, getDocs, query, updateDoc, where } from "firebase/firestore"

import { DateTime } from "luxon"
import { PopupService } from "./PopupService"
import { getLocationStr } from "../utils"
import { useAuthState } from "react-firebase-hooks/auth"

const fetchUser = async (userUID, raffleId, raffleTicketId) => {
  const col = doc(db, `users/${userUID}`)
  const snap = await getDoc(col)
  return snap.data()
}
const Wheel = ({ raffleId }) => {
  const [tickets, setTickets] = useState([])
  const [selectedTicket, setSelectedTicket] = useState(null)
  const [isSpinning, setIsSpinning] = useState(false)
  const [winner, setWinner] = useState(null)
  const [user] = useAuthState(auth)

  useEffect(() => {
    const fetchTickets = async () => {
      const ticketsCollection = collection(db, `raffles/${raffleId}/tickets`)
      const ticketSnapshot = await getDocs(ticketsCollection)
      const ticketList = ticketSnapshot.docs.map((doc) => {
        const data = doc.data()

        if (data.isWinner) {
          setWinner(data.code)
        }
        return data
      })
      setTickets(ticketList)
    }

    fetchTickets()
  }, [raffleId])

  const spinWheel = () => {
    setIsSpinning(true)
    const randomIndex = Math.floor(Math.random() * tickets.length)
    setTimeout(() => {
      setSelectedTicket(tickets[randomIndex])
      setIsSpinning(false)
    }, 3000) // 3 seconds spin time
  }

  const onMarkAsWinner = async () => {
    if (!selectedTicket) {
      return PopupService.setPopup("No ticket selected yet. Please spin the wheel")
    }

    const ticketId = selectedTicket.id

    // update the user ticket

    const raffleTicket = doc(db, `raffles/${raffleId}/tickets/${ticketId}`)
    // const ticketSnapshot = await getDoc(raffleTicket)
    // const ticket = ticketSnapshot.data()

    const winnerInfo = {
      isWinner: true,
      setWinnerDate: DateTime.now().toMillis(),
      setWinnerUserUID: user.uid,
    }
    await updateDoc(raffleTicket, winnerInfo)

    const ticketHolderId = selectedTicket.payerUID
    const userTicket = query(
      collection(db, `users/${ticketHolderId}/tickets`),
      where("code", "==", selectedTicket.code),
    )
    const userTicketSnapshot = await getDocs(userTicket)

    userTicketSnapshot.docs.map(async ({ ref }) => {
      // const data = ref.data()
      await updateDoc(ref, winnerInfo)
    })

    setWinner(selectedTicket.code)
    selectedTicket.isWinner = true
  }

  return (
    <Box className="flex flex-col gap-5 items-center justify-center h-screen">
      <Box className="flex flex-row flex-wrap gap-5 items-center justify-center w-50 h-50 overflow-auto">
        {tickets?.map((item, i) => {
          return (
            <Box>
              <Card
                sx={{ mx: "auto", mt: 4 }}
                style={{
                  backgroundColor: item.isWinner ? "green" : "transparent",
                  borderWidth: 1,
                  borderColor: selectedTicket?.code === item.code ? "blue" : "",
                }}
              >
                <CardContent>
                  <Button
                    variant="contained"
                    color="primary"
                    className="mt-4"
                    onClick={async () => {
                      const user = await fetchUser(item.payerUID)
                      PopupService.setPopup(
                        <Box>
                          <Box className="flex flex-row gap-5 items-center">
                            <Avatar src={user.profileImageUrl} />
                            <Box>
                              <Typography gutterBottom variant="h6" component="div">
                                <b>Code: </b>
                                {item.code}
                              </Typography>

                              <Typography gutterBottom variant="h6" component="div">
                                <b>Expiration Date: </b>
                                {DateTime.fromMillis(item.expirationDate).toLocaleString(
                                  DateTime.DATETIME_FULL,
                                )}
                              </Typography>

                              <Typography gutterBottom variant="h6" component="div">
                                User Info:
                              </Typography>

                              <div className="ml-10">
                                <Typography gutterBottom variant="h6" component="div">
                                  <b>Email: </b>
                                  {user.email}
                                </Typography>
                                <Typography gutterBottom variant="h6" component="div">
                                  <b>Name: </b>
                                  {user.name || user.displayName}
                                </Typography>
                                <Typography gutterBottom variant="h6" component="div">
                                  <b>Phone: </b>
                                  {user.phoneNumber}
                                </Typography>
                              </div>
                            </Box>
                          </Box>

                          <Typography fontSize={"smaller"}>
                            <b>Location:</b>
                            {getLocationStr(user.userAddress || user.selectedLocation)}
                          </Typography>
                        </Box>,
                      )
                    }}
                  >
                    <Typography gutterBottom variant="h6" component="div">
                      {item.code}
                    </Typography>
                  </Button>
                </CardContent>
              </Card>
            </Box>
          )
        })}
      </Box>
      <Box className="w-64 h-64 border-4 border-gray-300 rounded-full flex items-center justify-center relative">
        {isSpinning ? (
          <CircularProgress size={80} />
        ) : (
          <Box className="text-3xl font-bold text-center">
            {winner
              ? `${winner} Winner`
              : selectedTicket !== null
              ? selectedTicket.code
              : "Press Spin!"}
          </Box>
        )}
      </Box>
      {winner ? null : (
        <Button
          variant="contained"
          color="primary"
          className="mt-4"
          onClick={spinWheel}
          disabled={isSpinning || tickets.length === 0}
        >
          Spin the Wheel
        </Button>
      )}
      {!selectedTicket || winner ? null : (
        <Button
          variant="contained"
          color="primary"
          className="mt-4"
          disabled={isSpinning || tickets.length === 0}
          onClick={onMarkAsWinner}
        >
          {`${selectedTicket.code} - Mark as Winner`}
        </Button>
      )}
    </Box>
  )
}

export default Wheel
