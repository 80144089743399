// import React, { useCallback, useEffect, useState } from "react";
// import { collection, getCountFromServer, getDocs, limit, orderBy, query, startAfter } from "firebase/firestore";

import { DataProvider, ListComponent } from "../GenericListComponent"

import { FormLabel } from "@mui/material"
import { ImageWidget } from "./CustomWidgets"
import { PopupService } from "./PopupService"
import QRCode from "qrcode.react"

// import MUIDataTable from "mui-datatables";
// import { PopupService } from "./PopupService";
// import PropTypes from "prop-types";
// import QRCode from 'qrcode.react';
// import { db } from "../firebase";

// const TicketsTable = ({ raffleId }) => {
//   const [tickets, setTickets] = useState([]);
//   const [lastVisible, setLastVisible] = useState(null);
//   const [totalCount, setTotalCount] = useState(0);
//   const [page, setPage] = useState(0);
//   const [rowsPerPage, setRowsPerPage] = useState(10);

//   const fetchTotalCount = useCallback(async () => {
//     if (!raffleId) return;
//     const countQuery = collection(db, `raffles/${raffleId}/tickets`);
//     const snapshot = await getCountFromServer(countQuery);
//     setTotalCount(snapshot.data().count);
//   }, [raffleId]);

//   const fetchTickets = useCallback(async (pageSize, lastDoc) => {
//     if (!raffleId) return;

//     const ticketsQuery = lastDoc
//       ? query(
//         collection(db, `raffles/${raffleId}/tickets`),
//         orderBy("id"),
//         startAfter(lastDoc),
//         limit(pageSize)
//       )
//       : query(collection(db, `raffles/${raffleId}/tickets`), orderBy("id"), limit(pageSize));

//     const querySnapshot = await getDocs(ticketsQuery);
//     const ticketsData = querySnapshot.docs.map((doc) => doc.data());
//     const lastVisibleDoc = querySnapshot.docs[querySnapshot.docs.length - 1];

//     setLastVisible(lastVisibleDoc);
//     setTickets(ticketsData);
//   }, [raffleId]);

//   useEffect(() => {
//     fetchTotalCount();
//     fetchTickets(rowsPerPage, null);
//   }, [raffleId, fetchTickets, fetchTotalCount, rowsPerPage]);

//   const handlePageChange = (page) => {
//     setPage(page);
//     fetchTickets(rowsPerPage, lastVisible);
//   };

//   const handleRowsPerPageChange = (newRowsPerPage) => {
//     setRowsPerPage(newRowsPerPage);
//     setPage(0);
//     fetchTickets(newRowsPerPage, null);
//   };

//   const handleRowClick = (rowData, rowMeta) => {
//     const ticket = tickets[rowMeta.dataIndex];
//     PopupService.setPopup(<div className="flex flex-col align-center">
//       <div className="flex flex-col justify-center">
//         <QRCode value={ticket.qrCodeData} size={256} />
//       </div>
//       <p className="flex flex-wrap">{ticket.qrCodeData}</p>

//     </div>);
//   };

//   const columns = [
//     { name: "availableCount", label: "Available Count" },
//     { name: "raffleType", label: "Raffle Type" },
//     { name: "ticketValue", label: "Ticket Value" },
//     { name: "points", label: "Points" },
//     { name: "createdBy", label: "Created By" },
//     { name: "createdDate", label: "Created Date" },
//     { name: "disabled", label: "Disabled" },
//     { name: "expirationDate", label: "Expiration Date" },
//     { name: "id", label: "ID" },
//     { name: "index", label: "Index" },
//     { name: "isSold", label: "Is Sold" },
//     { name: "isWinner", label: "Is Winner" },
//     { name: "order", label: "Order" },
//     { name: "raffleId", label: "Raffle ID" }
//   ];

//   const options = {
//     filterType: "checkbox",
//     serverSide: true,
//     count: totalCount,
//     page,
//     rowsPerPage,
//     onChangePage: handlePageChange,
//     onChangeRowsPerPage: handleRowsPerPageChange,
//     onRowClick: handleRowClick
//   };

//   return (
//     <MUIDataTable
//       title={"Tickets List"}
//       data={tickets}
//       columns={columns}
//       options={options}
//     />
//   );
// };

// TicketsTable.propTypes = {
//   raffleId: PropTypes.string.isRequired
// };

// export default TicketsTable;

const data = {
  getListLocation(id) {
    return ["raffles", id, "tickets"]
  },
  EDIT_TITLE: "Edit RaffleTickets",
  ADD_TITLE: "Add Raffle Ticket",
  LOADING_MESSAGE: "Loading Raffle Tickets...",
  TITLE: "Raffle Tickets",
  IMAGES_BUCKET: "RaffleTicketsImages",

  onRowClick: (list, navigate) => (arr, index /*: {rowIndex: number, dataIndex: number}*/) => {
    // console.log("on row click----->", index.dataIndex)
    const ticket = list[index.dataIndex]
    // const id = ticket.id
    // navigate(`/sponsors/${id}`, { state })

    // const ticket = arr[index]
    // console.log("arr, index---->", arr, index)

    PopupService.setPopup(
      <div className="flex flex-col align-center">
        <div className="flex flex-col justify-center">
          <QRCode value={ticket.qrCodeData} size={256} />
        </div>
        <p className="flex flex-wrap">{ticket.qrCodeData}</p>
      </div>,
    )
  },

  columnsProperties: [
    { hidden: true, name: "id", label: "Id" },
    { name: "disabled", label: "Disabled", type: "boolean" },
    { hidden: true, name: "raffleId", label: "Raffle ID" },
    {
      hidden: true,
      name: "qrCodeDataUrl",
      label: "QR Code",
      options: {
        customBodyRender: (value) => {
          return (
            <div className="flex flex-wrap w-20 h-20 overflow-auto">
              <FormLabel>{value ? value : "N/A"}</FormLabel>
            </div>
          )
        },
      },
    },
    {
      required: false,
      name: "availableCount",
      label: "Available Count",
      type: "number",
      options: {
        customBodyRender: (value) => {
          return (
            <div className="flex flex-wrap">
              <FormLabel>{value ? value : "N/A"}</FormLabel>
            </div>
          )
        },
      },
    },
    { name: "raffleType", label: "Raffle Type" },
    {
      required: false,
      name: "ticketValue",
      label: "Ticket Value",
    },
    {
      required: false,
      name: "points",
      label: "Points",
    },
    { name: "createdDate", label: "Created Date" },
    { name: "expirationDate", label: "Expiration Date" },
    { name: "isSold", label: "Is Sold", type: "boolean" },
    { name: "isWinner", label: "Is Winner", type: "boolean" },
    { name: "createdBy", label: "Created By" },
    { name: "order", label: "Order", type: "number" },
    { name: "index", label: "Index" },

    // { required: false, name: "disabled", label: "Disabled", type: "boolean" },
    // { required: false, name: "expirationDate", label: "Expiration Date" },

    // { required: true, name: "name", label: "Name" },
    // { required: true, type: "integer", name: "order", label: "Order" },

    // { required: false, name: "description", label: "Description" },
    // { required: true, name: "language", label: "Language" },
  ],

  uiSchema: {
    qrCodeDataUrl: {
      "ui:widget": "image",
    },
    // isSold: {
    //   "ui:widget": "boolean",
    // },
    // isWinner: {
    //   "ui:widget": "boolean",
    // },
    // language: {
    //   "ui:widget": LanguageDropdownWidget,
    // },
    // location: {
    //   "ui:widget": LocationCustomWidget,
    // },
    // userIds: {
    //   items: {
    //     "ui:widget": AutocompleteWidget,
    //   },
    // },
    createdDate: {
      "ui:widget": "datetime",
      "ui:disabled": true,
    },
    createdBy: {
      "ui:disabled": true,
    },
    index: {
      "ui:disabled": true,
    },
    expirationDate: {
      "ui:widget": "datetime",
      "ui:disabled": true,
    },

    description: {
      "ui:widget": "textarea",
    },
  },

  // async imageProcessor(fromData, handleUpload, id) {
  //   if (fromData.iconUrl) {
  //     fromData.iconUrl = await handleUpload(id, this.IMAGES_BUCKET, fromData.iconUrl)
  //   }

  //   if (fromData.image) {
  //     fromData.image = await handleUpload(id, this.IMAGES_BUCKET, fromData.image)
  //   }

  //   return fromData
  // },
  widgets: { image: ImageWidget },
}

const TicketsTable = ({ raffleId }) => {
  return (
    <div>
      <DataProvider {...data} mainId={raffleId}>
        <ListComponent />
      </DataProvider>
    </div>
  )
}
export default TicketsTable
