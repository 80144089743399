import { Button, Modal, TextField } from '@mui/material';
import React, { useState } from 'react';

const EditModal = ({ open, handleClose, component, updateComponent }) => {
  const [label, setLabel] = useState(component?.label || '');

  const handleSave = () => {
    updateComponent({ ...component, label });
    handleClose();
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <div className="p-4 bg-white rounded shadow-lg max-w-md mx-auto mt-20">
        <h3>Edit Component</h3>
        <TextField
          label="Label"
          value={label}
          onChange={(e) => setLabel(e.target.value)}
          fullWidth
        />
        <Button onClick={handleSave} variant="contained" color="primary" className="mt-4">
          Save
        </Button>
      </div>
    </Modal>
  );
};

export default EditModal;
