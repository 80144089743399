import reduce from 'lodash.reduce';

const getMap = ({item, path}) => {
  if (!item) {
    return [];
  }
  let arr = reduce(
    item,
    (prev, cur, index) => {
      console.log(prev, cur, index);
      if (!index.match(/^id/i)) {
        prev.push(cur);
      }
      return prev;
    },
    [],
  );
  return arr;
};
export default getMap;
