import {
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  Grid2,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material"
import { Col, Row } from "react-bootstrap"
import {
  FieldValue,
  Timestamp,
  and,
  collection,
  doc,
  endAt,
  getDocs,
  limit,
  or,
  orderBy,
  query,
  startAfter,
  startAt,
  where,
} from "firebase/firestore"
import React, { useEffect, useState } from "react"
import { faImage, faPaperPlane } from "@fortawesome/free-solid-svg-icons"

// import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import MUIDataTable from "mui-datatables"
import { Spinner } from "@chakra-ui/react"
import { db } from "../../firebase"
import { motion } from "framer-motion"
import { useTranslation } from "react-i18next"

// interface User {
//   id: string;
//   fcmWebToken: string;
//   pushNotificationToken: string;
// }
const USERS_PAGE_SIZE = 10

const AdminNotificationsPage = () => {
  const { t } = useTranslation()
  const [users, setUsers] = useState([])
  const [selectedUsers, setSelectedUsers] = useState([])
  const [notificationTitle, setNotificationTitle] = useState("")
  const [notificationBody, setNotificationBody] = useState("")
  const [notificationName, setNotificationName] = useState("")
  const [notificationImage, setNotificationImage] = useState(null)
  const [deviceType, setDeviceType] = useState([])
  const [targetSegment, setTargetSegment] = useState("")
  const [scheduleTime, setScheduleTime] = useState(null)
  const [androidChannel, setAndroidChannel] = useState("")
  const [customData, setCustomData] = useState([])
  const [soundEnabled, setSoundEnabled] = useState(true)
  const [appleBadge, setAppleBadge] = useState(0)
  const [expirationTime, setExpirationTime] = useState(null)
  const [expirationUnit, setExpirationUnit] = useState("minutes")
  const [isSending, setIsSending] = useState(false)
  const [hasMore, setHasMore] = useState(true)
  const [lastVisible, setLastVisible] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [searchQuery, setSearchQuery] = useState("")

  //   const dropdownId = useId("dropdown-default")
  //   useEffect(() => {
  //     const fetchUsers = async () => {
  //       try {
  //         const snapshot = await getDocs(
  //           collection(db, "users"),

  //           and(
  //             where("pushNotifications", "==", true),
  //             or(where("email", "!=", ""), where("phoneNumber", "!=", "")),
  //           ),
  //         )
  //         const usersData = snapshot.docs.map((doc) => ({
  //           id: doc.id,
  //           ...doc.data(),
  //         }))
  //         setUsers(usersData)
  //       } catch (error) {
  //         console.error("Error fetching users:", error)
  //       }
  //     }
  //     fetchUsers()
  //   }, [])
  const fetchUsers = async (reset = false, search = "") => {
    setIsLoading(true)
    try {
      let userQuery
      if (search) {
        // Search query for displayName, email, or ID
        userQuery = query(
          collection(db, "users"),
          and(
            where("pushNotifications", "==", true),
            or(where("email", ">=", search), where("phoneNumber", ">=", search)),
          ),
          orderBy("displayName"),
          startAt(search),
          endAt(search + "\uf8ff"),
          limit(USERS_PAGE_SIZE),
        )
      } else {
        userQuery = reset
          ? query(
              collection(db, "users"),
              where("pushNotifications", "==", true),
              orderBy("displayName"),
              limit(USERS_PAGE_SIZE),
            )
          : query(
              collection(db, "users"),
              where("pushNotifications", "==", true),
              orderBy("displayName"),
              startAfter(lastVisible),
              limit(USERS_PAGE_SIZE),
            )
      }

      const snapshot = await getDocs(userQuery)
      const usersData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }))

      if (reset) {
        setUsers(usersData)
      } else {
        setUsers((prevUsers) => [...prevUsers, ...usersData])
      }

      setLastVisible(snapshot.docs[snapshot.docs.length - 1])
      setHasMore(usersData.length === USERS_PAGE_SIZE)
    } catch (error) {
      console.error("Error fetching users:", error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchUsers(true) // Fetch initial users on component mount
  }, [])

  const handleUserChange = (selectedIds) => {
    setSelectedUsers(selectedIds)
  }

  const handleAddCustomData = () => {
    setCustomData([...customData, { key: "", value: "" }])
  }

  const handleCustomDataChange = (index, key, value) => {
    const newCustomData = [...customData]
    newCustomData[index][key] = value
    setCustomData(newCustomData)
  }

  const handleSendNotification = async () => {
    if (!notificationTitle || !notificationBody || selectedUsers.length === 0) {
      alert("Please fill out all fields and select at least one user.")
      return
    }

    setIsSending(true)
    try {
      const batch = db.batch()

      selectedUsers.forEach((userId) => {
        const userRef = doc(db, "users", userId)
        batch.update(userRef, {
          notifications: FieldValue.arrayUnion({
            title: notificationTitle,
            body: notificationBody,
            name: notificationName,
            imageUrl: notificationImage ? URL.createObjectURL(notificationImage) : "",
            deviceType,
            targetSegment,
            scheduleTime: scheduleTime ? scheduleTime.toISOString() : "",
            androidChannel,
            customData,
            soundEnabled,
            appleBadge,
            expirationTime: expirationTime ? `${expirationTime} ${expirationUnit}` : "",
            timestamp: Timestamp.now(),
          }),
        })
      })

      await batch.commit()
      alert("Notification sent successfully!")
    } catch (error) {
      console.error("Error sending notification:", error)
    } finally {
      setIsSending(false)
      setNotificationTitle("")
      setNotificationBody("")
      setNotificationName("")
      setNotificationImage(null)
      setSelectedUsers([])
      setDeviceType([])
      setTargetSegment("")
      setScheduleTime(null)
      setAndroidChannel("")
      setCustomData([])
      setSoundEnabled(true)
      setAppleBadge(0)
      setExpirationTime(null)
    }
  }

  const handleLoadMoreUsers = () => {
    if (hasMore) {
      fetchUsers()
    }
  }

  const handleTableSearch = (searchText) => {
    setSearchQuery(searchText)
    if (searchText) {
      fetchUsers(true, searchText)
    } else {
      fetchUsers(true)
    }
  }

  const handleTableChange = (action, tableState) => {
    if (action === "rowSelectionChange") {
      const selectedData = tableState.selectedRows.data.map((row) => users[row.index].id)
      setSelectedUsers(selectedData)
    } else if (action === "search" && tableState.searchText !== searchQuery) {
      handleTableSearch(tableState.searchText)
    }
  }

  const columns = [
    { name: "displayName", label: t("name") },
    { name: "email", label: t("email") },
    { name: "id", label: t("user_id") },
  ]

  const options = {
    filter: true,
    filterType: "dropdown",
    responsive: "standard",
    selectableRows: "multiple",
    selectableRowsOnClick: true,
    onTableChange: handleTableChange,
  }

  return (
    <Container maxWidth="md" className="flex flex-col p-5 gap-1">
      <Typography variant="h4" className="mb-5 text-center">
        {t("send_notifications")}
      </Typography>

      <MUIDataTable title={t("select_users")} data={users} columns={columns} options={options} />
      <div className="flex justify-center mt-4">
        <Button onClick={handleLoadMoreUsers} disabled={isLoading || !hasMore} appearance="primary">
          {isLoading ? <Spinner size="medium" /> : t("load_more_users")}
        </Button>
      </div>

      {/* Notification Fields */}
      <TextField
        label={t("notification_name")}
        value={notificationName}
        onChange={(e) => setNotificationName(e.target.value)}
        fullWidth
        className="mb-4"
      />

      <TextField
        label={t("notification_title")}
        value={notificationTitle}
        onChange={(e) => setNotificationTitle(e.target.value)}
        fullWidth
        className="mb-4"
      />

      <TextField
        label={t("notification_body")}
        value={notificationBody}
        onChange={(e) => setNotificationBody(e.target.value)}
        multiline
        rows={4}
        fullWidth
        className="mb-4"
      />

      {/* Notification Image */}
      <input
        accept="image/*"
        id="upload-notification-image"
        type="file"
        style={{ display: "none" }}
        onChange={(e) => setNotificationImage(e.target.files?.[0] || null)}
      />
      <label htmlFor="upload-notification-image">
        <Button variant="outlined" component="span" startIcon={<FontAwesomeIcon icon={faImage} />}>
          {t("upload_image")}
        </Button>
      </label>

      {/* Device Type */}
      <FormControlLabel
        control={
          <Checkbox checked={soundEnabled} onChange={() => setSoundEnabled(!soundEnabled)} />
        }
        label={t("sound_enabled")}
      />

      <TextField
        label={t("apple_badge")}
        type="number"
        value={appleBadge}
        onChange={(e) => setAppleBadge(Number(e.target.value))}
        fullWidth
        className="mb-4"
      />

      {/* Expiration Time */}
      <Grid2 container spacing={2}>
        <Grid2 item xs={6}>
          <TextField
            label={t("expiration_time")}
            type="number"
            value={expirationTime}
            onChange={(e) => setExpirationTime(Number(e.target.value))}
            fullWidth
          />
        </Grid2>
        <Grid2 item xs={6}>
          <Select
            value={expirationUnit}
            onChange={(e) => setExpirationUnit(e.target.value)}
            fullWidth
          >
            <MenuItem value="minutes">{t("minutes")}</MenuItem>
            <MenuItem value="hours">{t("hours")}</MenuItem>
            <MenuItem value="days">{t("days")}</MenuItem>
            <MenuItem value="weeks">{t("weeks")}</MenuItem>
          </Select>
        </Grid2>
      </Grid2>

      {/* Send Notification Button */}
      <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<FontAwesomeIcon icon={faPaperPlane} />}
          onClick={handleSendNotification}
          disabled={isSending}
          fullWidth
        >
          {isSending ? <CircularProgress size={24} /> : t("send_notification")}
        </Button>
      </motion.div>
    </Container>
  )
}

export default AdminNotificationsPage

// import * as React from 'react';

// import Box from '@mui/material/Box';
// import Button from '@mui/material/Button';
// import NotificationConfig from '../NotificationConfig';
// import Step from '@mui/material/Step';
// import StepLabel from '@mui/material/StepLabel';
// import Stepper from '@mui/material/Stepper';
// import Typography from '@mui/material/Typography';

// const steps = [{
// 	label: 'Notification',
// 	isOptional: false,
// }, {
// 	label: 'Target',
// 	isOptional: false,
// }, {
// 	label: 'Scheduling',
// 	isOptional: false,
// }, {
// 	label: 'Conversion events (optional)',
// 	isOptional: true,
// }, {
// 	label: 'Additional options (optional)',
// 	isOptional: true,
// }];
// const stepComponents = {
// 	0: () => <NotificationConfig />,
// 	1: () => null,
// 	2: () => null,
// 	3: () => null,
// 	4: () => null,
// 	5: () => null,
// };
// export default function Notification() {
// 	const [activeStep, setActiveStep] = React.useState(0);
// 	const [skipped, setSkipped] = React.useState(new Set());

// 	const isStepOptional = (step) => {
// 		return step === 4 && step === 5;
// 	};

// 	const isStepSkipped = (step) => {
// 		return skipped.has(step);
// 	};

// 	const handleNext = () => {
// 		let newSkipped = skipped;
// 		if (isStepSkipped(activeStep)) {
// 			newSkipped = new Set(newSkipped.values());
// 			newSkipped.delete(activeStep);
// 		}

// 		setActiveStep((prevActiveStep) => prevActiveStep + 1);
// 		setSkipped(newSkipped);
// 	};

// 	const handleBack = () => {
// 		setActiveStep((prevActiveStep) => prevActiveStep - 1);
// 	};

// 	const handleSkip = () => {
// 		if (!isStepOptional(activeStep)) {
// 			// You probably want to guard against something like this,
// 			// it should never occur unless someone's actively trying to break something.
// 			throw new Error("You can't skip a step that isn't optional.");
// 		}

// 		setActiveStep((prevActiveStep) => prevActiveStep + 1);
// 		setSkipped((prevSkipped) => {
// 			const newSkipped = new Set(prevSkipped.values());
// 			newSkipped.add(activeStep);
// 			return newSkipped;
// 		});
// 	};

// 	const handleReset = () => {
// 		setActiveStep(0);
// 	};

// 	const Component = stepComponents[activeStep];

// 	return (
// 		<Box sx={{ width: '100%' }}>
// 			<Stepper activeStep={activeStep}>
// 				{steps.map(({ label }, index) => {
// 					const stepProps = {};
// 					const labelProps = {};
// 					if (isStepOptional(index)) {
// 						labelProps.optional = (
// 							<Typography variant="caption">Optional</Typography>
// 						);
// 					}
// 					if (isStepSkipped(index)) {
// 						stepProps.completed = false;
// 					}
// 					return (
// 						<Step key={label} {...stepProps}>
// 							<StepLabel {...labelProps}>{label}</StepLabel>
// 						</Step>
// 					);
// 				})}
// 			</Stepper>
// 			{activeStep === steps.length ? (
// 				<React.Fragment>
// 					<Typography sx={{ mt: 2, mb: 1 }}>
// 						All steps completed - you&apos;re finished
// 					</Typography>
// 					<Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
// 						<Box sx={{ flex: '1 1 auto' }} />
// 						<Button onClick={handleReset}>Reset</Button>
// 					</Box>
// 				</React.Fragment>
// 			) : (
// 				<React.Fragment>
// 					<Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography>
// 					<Component />
// 					<Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
// 						<Button
// 							color="inherit"
// 							disabled={activeStep === 0}
// 							onClick={handleBack}
// 							sx={{ mr: 1 }}
// 						>
// 							Back
// 						</Button>
// 						<Box sx={{ flex: '1 1 auto' }} />
// 						{isStepOptional(activeStep) && (
// 							<Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
// 								Skip
// 							</Button>
// 						)}
// 						<Button onClick={handleNext}>
// 							{activeStep === steps.length - 1 ? 'Finish' : 'Next'}
// 						</Button>
// 					</Box>
// 				</React.Fragment>
// 			)}
// 		</Box>
// 	);
// }
