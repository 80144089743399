import "./index.css"
import "./router.css"
import "@fortawesome/react-fontawesome"

import { PopupProvider, PopupService } from "./components/PopupService"
import React, { useEffect, useState } from "react"

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { I18nextProvider } from "react-i18next"
import { LoadingProvider } from "./Loading"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { LocationProvider } from "./LocationSelector"
import { MuiProvider } from "./MuiProvider"
import Profile from "./components/Profile"
import ReactDOM from "react-dom/client"
import { Routes } from "./routes"
import { createTheme } from "@mui/material"
import i18n from "./i18n"
import reportWebVitals from "./reportWebVitals"

const theme = createTheme({
  palette: {
    mode: "light", // or 'dark'
  },
})
function Test1() {
  const [testing, setTesting] = useState("Original")

  useEffect(() => {
    setTimeout(() => setTesting("oops"), 1000)
  }, [])
  return (
    <h1>
      Testing Value: {testing} <Profile />
    </h1>
  )
}
function Test() {
  useEffect(() => {
    PopupService.setPopup(<Test1 />)
  }, [])
}
const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <I18nextProvider i18n={i18n}>
    <React.StrictMode>
      <LocationProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <MuiProvider theme={theme}>
            <LoadingProvider>
              <PopupProvider>
                <Routes />
              </PopupProvider>
            </LoadingProvider>
          </MuiProvider>
        </LocalizationProvider>
      </LocationProvider>
    </React.StrictMode>
  </I18nextProvider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
