import React from 'react';
import UploadImage from '../../../utils/UploadImage';
import {Input} from '@mui/material';
import {Label} from '@fluentui/react';

export const ImageWidget = ({bucket}) => props => {
  return (
    <>
      <Label>{props.label}</Label>
      <Input
        value={props.value}
        onChange={e => props.onChange(e.target.value)}
      />
      <UploadImage
        url={props.value}
        bucket={bucket}
        getUrl={url => {
          props.onChange(url);
        }}
      />
    </>
  );
};
