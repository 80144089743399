import {DataTable} from './CreateEditOptions/DataTable';

export function SingleObject({
  path,
  data,
  onEditSuccess,
  title,
  schema,
  columns,
  imagePropName
}) {
  return (
    <DataTable
      hideAddButton
      isCollection
      imagePropName={imagePropName}
      path={path}
      // bucket={BUCKET}
      title={title}
      // addTitle={ADD_TITLE}
      setLoading={() => {}}
      setData={() => {}}
      getMap={() => {}}
      loading={false}
      data={[data]}
      columns={columns}
      options={{
        selectableRows: 'none',
        filter: false,
        print: false,
        search: false,
        viewColumns: false,
        download: false,
        disableToolbarSelect: true,
      }}
      schema={schema}
      onAEditSuccess={onEditSuccess}
    />
  );
}
