import {
  AdminPanelSettings,
  AirplaneTicket,
  Business as BusinessIcon,
  Home as HomeIcon,
  LanguageRounded,
  LiveHelpRounded,
  LocationSearchingOutlined,
  Login as LoginIcon,
  MonetizationOn,
  SettingsApplicationsRounded,
  SupervisedUserCircle as UsersIcon,
} from "@mui/icons-material"
import { BrowserRouter, Navigate, Route, Routes as RoutesBase } from "react-router-dom"
import { BusinessDetail, BusinessesComponent } from "./BusinessesComponent"
import { Loading, LoadingService } from "./Loading"
import React, { useEffect, useState } from "react"

import AdminPanelConfig from "./components/AdminPanelConfig";
// import { BusinessAdsComponent } from "./BusinessAdsComponent"
// import { BusinessOffersComponent } from "./BusinessOfferComponent"
import Countries from "./components/Countries"
// import EditSponsor from "./components/EditSponsor"
import ErrorPage from "./error-page"
import Home from "./components/Home"
import Login from "./components/Login"
import LoginsScreen from "./components/LoginScreen"
import Notification from "./components/Notification"
import Onboarding from "./components/Onboarding"
import { PopupService } from "./components/PopupService"
import RaffleDetail from "./components/RaffleDetail"
import RaffleEdit from "./components/RaffleEdit"
import RaffleTickets from "./components/RaffleTickets"
import Raffles from "./components/Raffles"
import ReceiptContentExtractor from "./components/ReceiptContentExtractor"
import Root from "./root"
import SponsorTabs from "./components/SponsorTabs"
import Sponsors from "./components/Sponsors"
import Tickets from "./components/Tickets"
import Translations from "./components/Translations"
import Users from "./components/Users"
import { auth } from "./firebase"
import i18n from "./i18n"
import { useAuthState } from "react-firebase-hooks/auth"

export const routes = [
  { name: "", title: "Home", icon: <HomeIcon />, element: <Home />, auth: false },
  { name: "login", title: "Login", icon: <LoginIcon />, element: <Login />, auth: false },
  { name: "users", title: "Users", icon: <UsersIcon />, element: <Users />, auth: true },
  { name: "tickets", title: "Tickets", icon: <MonetizationOn />, element: <Tickets />, auth: true },
  {
    name: "countries",
    title: "Countries",
    icon: <LocationSearchingOutlined />,
    element: <Countries />,
    auth: true,
  },
  {
    name: "translations",
    title: "Translations",
    icon: <LanguageRounded />,
    element: <Translations />,
    auth: true,
  },
  {
    name: "onboarding",
    title: "Onboarding",
    icon: <LiveHelpRounded />,
    element: <Onboarding />,
    auth: true,
  },
  {
    name: "notifications",
    title: "Notifications",
    icon: <SettingsApplicationsRounded />,
    element: <Notification />,
    auth: true,
  },
  {
    name: "login-screen-config",
    title: "Login Screen",
    icon: <SettingsApplicationsRounded />,
    element: <LoginsScreen />,
    auth: true,
  },
  {
    name: "receipt-content-extractor",
    title: "Receipt Content Extractor",
    icon: <SettingsApplicationsRounded />,
    element: <ReceiptContentExtractor />,
    auth: true,
  },
  { name: "raffles", title: "Raffles", icon: <AirplaneTicket />, element: <Raffles />, auth: true },
  {
    name: "raffles/:id",
    title: "ViewRaffle",
    icon: <AirplaneTicket />,
    element: <RaffleDetail />,
    auth: true,
    hidden: true,
  },
  {
    name: "raffles/edit/:id",
    title: "EditRaffles",
    icon: <AirplaneTicket />,
    element: <RaffleEdit />,
    auth: true,
    hidden: true,
  },
  {
    name: "raffles/:id/generate-tickets",
    icon: <AirplaneTicket />,
    element: <RaffleTickets />,
    auth: true,
    hidden: true,
  },
  {
    name: "generate/:id",
    icon: <AirplaneTicket />,
    element: <RaffleTickets />,
    auth: true,
    hidden: true,
  },
  {
    name: "businesses",
    title: "Businesses",
    icon: <BusinessIcon />,
    element: <BusinessesComponent />,
    auth: true,
    hidden: false,
  },
  {
    name: "businesses/:id",
    title: "Businesses Details",
    icon: <BusinessIcon />,
    element: <BusinessDetail />,
    auth: true,
    hidden: true,
  },
  {
    name: "sponsors",
    title: "Sponsors",
    icon: <BusinessIcon />,
    element: <Sponsors />,
    auth: true,
  },
  {
    name: "sponsors/:id",
    title: "Sponsor",
    icon: <BusinessIcon />,
    element: <SponsorTabs />,
    auth: true,
    hidden: true,
  },
  {
    name: "pages",
    title: "Pages",
    icon: <AdminPanelSettings />,
    element: <AdminPanelConfig />,
    auth: true,
  },
]

export const Routes = () => {
  const [isInitialized, setIsInitialized] = useState()
  const [filteredRoutes, setFilteredRoutes] = useState([])
  const [user, loading] = useAuthState(auth)

  useEffect(() => {
    setFilteredRoutes(routes.filter((x) => x.auth === !!user))

    const initialize = async () => {
      try {
        LoadingService.setLoading(true)
        await i18n.init()
        setIsInitialized(true)
      } catch (error) {
        PopupService.pushPopup(error.message)
      } finally {
        LoadingService.setLoading(false)
      }
    }

    initialize()
  }, [user])

  if (!isInitialized || loading) {
    return <Loading />
  }
  // return JSON.stringify(filteredRoutes)
  return (
    <BrowserRouter>
      <RoutesBase>
        <Route path="" element={<Root />} errorElement={ErrorPage} loader={Loading}>
          {filteredRoutes.map((item, i) => (
            <Route key={`${item.name}-${i}`} path={item.name} element={item.element} />
          ))}
        </Route>
        <Route path="*" element={<Navigate to="/" replace />} />
      </RoutesBase>
    </BrowserRouter>
  )
}
