import {doc, updateDoc, deleteField, writeBatch} from 'firebase/firestore';
import {db} from '../../firebase';

export const options = ({path, data, onSuccess}) => {
  return {
    filterType: 'checkbox',
    onRowsDelete: async (rowsDeleted, rowData, newTableData) => {
      console.log({rowsDeleted, rowData, newTableData});

      if (typeof path === 'string') {
        path = path.split('/');
      }
      const cityRef = doc(db, ...path);

      // Get a new write batch
      // const batch = writeBatch(db);

      rowsDeleted.data.forEach(async element => {
        const row = data[element.dataIndex];
        const key = row ? row.key : '';
        const laRef = doc(db, ...path);
        await updateDoc(cityRef, {
          [key]: deleteField(),
        });
      });

      onSuccess && onSuccess(data);

      // await updateDoc(cityRef, {
      //   [key]: deleteField(),
      // });

      // Commit the batch
      // await batch.commit();

      return false;
    },
  };
};
