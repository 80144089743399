import {doc, updateDoc, setDoc} from 'firebase/firestore';
import reduce from 'lodash.reduce';
import {db} from '../../../firebase';

const update = async ({path, key, data, persistKey, dataKeyValue, setOrUpdate}) => {
  if (!path) {
    console.error('Path is required to update data.');
  }

  if (typeof path === 'string') {
    path = path.split('/');
  }

  const ref = doc(db, ...path);

  if (typeof data !== 'string') {
    data = reduce(
      data,
      (result, value, key) => {
        result[key] = typeof result[key] === 'undefined' ? '' : result[key];
        return result;
      },
      data,
    );
  }

  if (key) {
    data = persistKey
      ? {...data, key}
      : // : dataKeyValue
        // ? data
        {
          [key]: data,
        };
  }
  // if (dataKeyValue) {
  // }
  if (dataKeyValue || key) {
    const mergeFields = dataKeyValue
      ? `${key ? key + '.' : ''}${dataKeyValue || ''}`
      : key;

    return await setDoc(ref, data, {
      mergeFields: [mergeFields],
    });
  }
  if (setOrUpdate){
    return await setDoc(ref, data)
  }
  
  return await updateDoc(ref, data)
};

export default update;
