// import {Form, FormEdit, FormGrid, Grid} from '@formio/react';
import React, {useEffect} from 'react';
import {useState} from 'react';
import {getColumns} from './getColumns';
import {getData} from '../core/CreateEditOptions/getData';
import {DataTable} from '../core/CreateEditOptions/DataTable';
import update from '../core/CreateEditOptions/update';
import {updateData} from '../core/CreateEditOptions/updateData';
import getMap from './getMap';
import {options} from './options';
import {schema} from './schema';
import add from '../core/CreateEditOptions/add';

const PATH = 'app-configurations/onboarding/pages';
const BUCKET = 'onboarding/assets';
const TITLE = 'Onboarding Config';
const ADD_TITLE = 'Add new Onboarding Config';
const IMAGE_PROP_NAME = 'background';

const Main = () => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState();
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    getData({setData, path: PATH, getMap, isCollection: true});
  }, []);

  useEffect(() => {
    if (data?.length) {
      const resultColumns = getColumns({
        imagePropName: IMAGE_PROP_NAME,
        data,
        setLoading,
        setData,
        path: PATH,
        onEditSuccess: async ({result, close, id}) => {
          await updateData({
            path: `${PATH}/${id}`,
            setLoading,
            data: result.formData,
          });

          getData({
            setData,
            path: PATH,
            getMap,
            isCollection: true,
            setLoading,
          });
          close();
        },
      });
      setColumns(resultColumns);
    }
  }, [data]);

  if (!data || !Array.isArray(data)) {
    setData([]);
  }

  return (
    <DataTable
      isCollection
      imagePropName={IMAGE_PROP_NAME}
      path={PATH}
      bucket={BUCKET}
      title={TITLE}
      addTitle={ADD_TITLE}
      setLoading={setLoading}
      setData={setData}
      getMap={getMap}
      loading={loading}
      data={data}
      columns={columns}
      options={options}
      schema={schema}
      onSuccess={() => {
        getData({setData, path: PATH, getMap, isCollection: true, setLoading});
      }}
      onEditSuccess={() => {
        getData({setData, path: PATH, getMap, isCollection: true, setLoading});
      }}
      onAddSuccess={async ({result, close}) => {
    
        await add({
          path: PATH,
          setLoading,
          data: result.formData,
        });

        getData({setData, path: PATH, getMap, isCollection: true, setLoading});
        close();
      }}
    />
  );
};

export default Main;
