import React, {useState} from 'react';

import Form from '@rjsf/material-ui';
import {fields} from './fields';
import {uiSchema} from './uiSchema';
import validator from '@rjsf/validator-ajv8';
import {widgets} from './widgets';

const CreateEdit = ({
  onSuccess,
  title,
  data,
  bucket,
  schema,
  imagePropName,
}) => {
  schema = typeof schema === 'function' ? schema({title}) : schema;
  return (
    <Form
      formData={data}
      fields={fields({bucket})}
      widgets={widgets({bucket})}
      schema={schema}
      uiSchema={uiSchema({bucket, propName: imagePropName})}
      validator={validator}
      // onChange={console.log('changed')}
      onSubmit={result => {
        onSuccess && onSuccess(result);
      }}
      onError={error => console.error('errors', error)}
    />
  );
};

export default CreateEdit;
