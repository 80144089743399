export const contentSchema = {
  title: 'Content',
  type: 'object',
  required: ['order'],
  properties: {
    disabled: { type: 'boolean', title: 'Disabled' },
    order: { type: 'number', title: 'Order' },
    text: { type: 'string', title: 'Text' },
    translationKey: { type: 'string', title: 'Translation Key' },
  },
};
