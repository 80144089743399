import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  styled,
  useMediaQuery,
} from "@mui/material"
import React, { createContext, useContext, useEffect, useState } from "react"

import { CloseIcon } from "@chakra-ui/icons"

export const PopupService = {
  pushPopup: (popupVal, titleVal, style) => console.log("PopupService--pushPopup-->", popupVal),
  setPopup: (...args) => console.log("PopupService--setPopup-->", args),
  getPopup: () => console.log("getPopup---->"),
  setTitle: (title) => console.log("PopupService--title-->", title),
}

const PopupContext = createContext({ popup: null, title: "" })

export const usePopup = () => {
  return useContext(PopupContext)
}
let popupCount = 1
const BootstrapDialog = styled(Dialog)(({ theme, style }) => {
  const matches = useMediaQuery(theme.breakpoints.up("sm"))
  return {
    "& .MuiPaper-root": {
      width: "100%",
      margin: matches ? 30 : 5,
      height: style?.height,
      marginRight: matches ? 60 : 0,
      maxWidth: matches ? 600 : "100%",

      ...style,
    },
  }
})

export const PopupProvider = (props) => {
  const { children, defaultPopup = null } = props
  const [popup, setPopup] = useState(defaultPopup)

  const [title, setTitle] = useState("")
  useEffect(() => {
    PopupService.setPopup = (val, titleVal, style, onClosed) => {
      if (!val) {
        return setPopup(null)
      }
      const styleVal = style ? style : typeof titleVal !== "string" ? {} : titleVal
      setPopup([
        <ModalPopup
          key={popupCount}
          title={typeof titleVal === "string" ? titleVal : "" || title}
          popup={val}
          styleValue={styleVal}
          onClosed={onClosed}
        />,
      ])
    }
    PopupService.getPopup = () => popup
    PopupService.setTitle = setTitle
    PopupService.pushPopup = (popupVal, titleVal, style) => {
      setPopup((val) => {
        const key = popupCount++

        return [
          ...(Array.isArray(val) ? val : [val]),
          <ModalPopup
            style={style}
            key={`${key}-${titleVal || ""}`}
            title={titleVal || title}
            popup={popupVal}
          />,
        ]
      })
    }
  }, [])
  // return null
  return (
    <PopupContext.Provider value={{ popup, title }}>
      {children}

      {popup}
    </PopupContext.Provider>
  )
}

export function ModalPopup(props) {
  const { title, popup, type, style, showOk, styleValue, onClosed } = props
  const [modalVisible, setModalVisible] = useState(true)

  useEffect(() => {
    setModalVisible(true)
  }, [popup])

  const styleValProp = typeof styleValue === "string" ? {} : styleValue
  const className = typeof styleValue === "string" ? styleValue : ""
  // const classes = className.split(" ").reduce((prev, cur) => {
  //   return {
  //     [cur]: cur,
  //     ...prev,
  //   }
  // }, {})

  if (!popup) {
    return null
  }
  
  return (
    <BootstrapDialog
      open={modalVisible}
      style={styleValProp || style}
      className={className}
      onClose={onClosed}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "baseline",
        }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {title}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            onClosed && onClosed()
            setModalVisible(false)
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </div>
      <DialogContent style={style}>
        <RenderElement element={popup} />
        {!!showOk && (
          <Button
            fullWidth
            size="medium"
            variant="outlined"
            color={type}
            onClick={() => setModalVisible(false)}
          >
            OK
          </Button>
        )}
      </DialogContent>
    </BootstrapDialog>
  )
}

const RenderElement = ({ element }) => {
  // If it's a string, just return it directly
  if (typeof element === "string") {
    return element
  }

  // If it's a function, execute it to get the returned element
  if (typeof element === "function") {
    const Element = element
    const testElement = Element({})

    // Check if the returned value is a valid React element
    if (React.isValidElement(testElement)) {
      // If the element is a custom component, add the close property
      if (typeof testElement.type !== "string") {
        return <Element close />
      } else {
        return <Element />
      }
    }
  }

  // If it's already a valid React element, render it directly
  if (React.isValidElement(element)) {
    // If the element is a custom component, add the close property
    if (typeof element.type !== "string") {
      return React.cloneElement(element, { close: true })
    } else {
      return element
    }
  }

  // Return null if none of the above conditions are met
  return null
}

// import {
//   Button,
//   Dialog,
//   DialogContent,
//   DialogTitle,
//   IconButton,
//   styled,
//   useMediaQuery,
// } from "@mui/material"
// import React, { createContext, useContext, useEffect, useState } from "react"

// import { CloseIcon } from "@chakra-ui/icons"

// export const PopupService = {
//   pushPopup: (popupVal, titleVal, style) => console.log("PopupService--pushPopup-->", popupVal),
//   setPopup: (...args) => console.log("PopupService--setPopup-->", args),
//   getPopup: () => console.log("getPopup---->"),
//   setTitle: (title) => console.log("PopupService--title-->", title),
// }

// const PopupContext = createContext({ popup: null, title: "" })

// export const usePopup = () => {
//   return useContext(PopupContext)
// }

// let popupCount = 1
// const BootstrapDialog = styled(Dialog)(({ theme, style }) => {
//   const matches = useMediaQuery(theme.breakpoints.up("sm"))
//   return {
//     "& .MuiPaper-root": {
//       width: "100%",
//       margin: matches ? 30 : 5,
//       height: style?.height,
//       marginRight: matches ? 60 : 0,
//       maxWidth: matches ? 600 : "100%",

//       ...style,
//     },
//   }
// })

// export const PopupProvider = (props) => {
//   const { children, defaultPopup = null } = props
//   const [popup, setPopup] = useState(defaultPopup)
//   const [title, setTitle] = useState("")

//   useEffect(() => {
//     PopupService.setPopup = (val, titleVal, style, onClosed) => {
//       if (!val) {
//         return setPopup(null)
//       }
//       const styleVal = style ? style : typeof titleVal !== "string" ? {} : titleVal
//       setPopup([
//         <ModalPopup
//           key={popupCount}
//           title={typeof titleVal === "string" ? titleVal : "" || title}
//           popup={typeof val === "function" ? <val setPopup={setPopup} /> : val}
//           styleValue={styleVal}
//           onClosed={onClosed}
//         />,
//       ])
//     }
//     PopupService.getPopup = () => popup
//     PopupService.setTitle = setTitle
//     PopupService.pushPopup = (popupVal, titleVal, style) => {
//       setPopup((val) => {
//         const key = popupCount++

//         return [
//           ...(Array.isArray(val) ? val : [val]),
//           <ModalPopup
//             style={style}
//             key={`${key}-${titleVal || ""}`}
//             title={titleVal || title}
//             popup={popupVal}
//           />,
//         ]
//       })
//     }
//   }, [popup, title])

//   return (
//     <PopupContext.Provider value={{ popup, title }}>
//       {children}

//       {popup}
//     </PopupContext.Provider>
//   )
// }

// const $dialogRoot = {
//   display: "flex",
//   flexDirection: "row",
//   justifyContent: "space-between",
//   alignItems: "baseline",
// };

// export function ModalPopup(props) {
//   const { title, popup, type, style, showOk, styleValue, onClosed } = props
//   const [modalVisible, setModalVisible] = useState(true)
//   const [ModalContent, setModalContent] = useState(null)

//   useEffect(() => {
//     setModalVisible(!!popup)

//     if (!popup) {
//       return
//     }

//     const popupProps = { close: () => setModalVisible(false) }
//     if (typeof popup === "function") {
//       setModalContent(React.cloneElement(popup, popupProps))
//     } else {
//       setModalContent(
//         React.Children.map(popup, (child, key) => {
//           if (React.isValidElement(child)) {
//             return React.cloneElement(child, popupProps)
//           }
//           return child
//         }),
//       )
//     }
//   }, [popup])

//   if (!popup || !ModalContent) {
//     return null
//   }

//   const styleValProp = typeof styleValue === "string" ? {} : styleValue
//   const className = typeof styleValue === "string" ? styleValue : ""
//   // const classes = className.split(" ").reduce((prev, cur) => {
//   //   return {
//   //     [cur]: cur,
//   //     ...prev,
//   //   }
//   // }, {})

//   return (
//     <BootstrapDialog
//       open={modalVisible}
//       style={styleValProp || style}
//       className={className}
//       onClose={onClosed}
//     >
//       <div
//         style={$dialogRoot}
//       >
//         <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
//           {title}
//         </DialogTitle>
//         <IconButton
//           aria-label="close"
//           onClick={() => {
//             onClosed && onClosed()
//             setModalVisible(false)
//           }}
//           sx={{
//             position: "absolute",
//             right: 8,
//             top: 8,
//             color: (theme) => theme.palette.grey[500],
//           }}
//         >
//           <CloseIcon />
//         </IconButton>
//       </div>
//       <DialogContent style={style}>
//         {/* {ModalContent} */}
//         {React.Children.map(popup, (child, key) => {
//           if (React.isValidElement(child)) {
//             const popupProps = { close: () => setModalVisible(false), key }
//             return React.cloneElement(child, popupProps)
//           }
//           return child
//         })}
//         {!!showOk && (
//           <Button
//             fullWidth
//             size="medium"
//             variant="outlined"
//             color={type}
//             onClick={() => setModalVisible(false)}
//           >
//             OK
//           </Button>
//         )}
//       </DialogContent>
//     </BootstrapDialog>
//   )
// }
