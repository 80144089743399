import {
  Autocomplete,
  Avatar,
  CircularProgress,
  FormLabel,
  TextField,
  Tooltip,
} from "@mui/material"
import { DataProvider, ListComponent } from "../GenericListComponent"
import { ImageWidget, LanguageDropdownWidget, SelectWidget } from "./CustomWidgets"
import { collection, getDocs, limit, query, startAfter, where } from "firebase/firestore"
import { useEffect, useState } from "react"

import { LocationCustomWidget } from "./LocationCustomWidget"
import { db } from "../firebase"

const AutocompleteWidget = (props) => {
  const { value = "", onChange, id } = props // Ensure value is always a string
  console.log("AutocompleteWidget value:", value)

  const [users, setUsers] = useState([])
  const [userSearch, setUserSearch] = useState("")
  const [loadingUsers, setLoadingUsers] = useState(false)
  const [lastVisibleUser, setLastVisibleUser] = useState(null)

  const fetchUsers = async (searchTerm = "", startAfterUser = null) => {
    setLoadingUsers(true)
    const usersRef = collection(db, "users")

    let userQuery = query(
      usersRef,
      where("name", ">=", searchTerm || ""), // Only fetch users where 'name' field exists and matches the search
      where("name", "<=", (searchTerm || "") + "\uf8ff"),
      limit(100),
    )

    if (startAfterUser) {
      userQuery = query(userQuery, startAfter(startAfterUser))
    }

    const querySnapshot = await getDocs(userQuery)
    const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1]
    setLastVisibleUser(lastVisible)

    const userList = querySnapshot.docs
      .map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }))
      .filter((user) => user.name) // Ensure the user has a name

    setUsers(userList)
    setLoadingUsers(false)
  }

  useEffect(() => {
    fetchUsers()
  }, [])

  const handleUserSearchChange = (event, value) => {
    setUserSearch(value)
    fetchUsers(value)
  }

  return (
    <Autocomplete
      options={users}
      value={users.find((user) => user.id === value) || null} // Find the selected user
      getOptionLabel={(option) => option.name || option.id || "Unknown User"}
      onChange={(event, newValue) => {
        onChange(newValue ? newValue.id : "") // Set the selected user's ID
      }}
      inputValue={userSearch}
      onInputChange={handleUserSearchChange}
      loading={loadingUsers}
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          {option.name || option.id || "Unknown User"}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Select User"
          variant="outlined"
          fullWidth
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loadingUsers ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  )
}
const data = {
  getListLocation(id) {
    return ["sponsors"]
  },
  EDIT_TITLE: "Edit Sponsors",
  ADD_TITLE: "Add Sponsor",
  LOADING_MESSAGE: "Loading sponsors...",
  TITLE: "Sponsors",
  IMAGES_BUCKET: "sponsorsImages",

  onRowClick: (list, navigate) => (arr, index /*: {rowIndex: number, dataIndex: number}*/) => {
    console.log("on row click----->", index.dataIndex)
    const state = list[index.dataIndex]
    const id = state.id
    navigate(`/sponsors/${id}`, { state })
  },

  columnsProperties: [
    { hidden: true, name: "id", label: "Id" },
    { required: false, name: "disabled", label: "Disabled", type: "boolean" },
    { required: false, name: "expirationDate", label: "Expiration Date" },

    { required: true, name: "name", label: "Name" },
    { required: true, type: "integer", name: "order", label: "Order" },

    {
      type: "string",
      name: "iconUrl",
      label: "Icon URL",

      options: {
        customBodyRender: (value) => {
          return (
            <div className="flex flex-col flex-wrap overflow-auto w-40">
              {!value ? null : <img src={value} />}
            </div>
          )
        },
      },
    },
    {
      type: "string",
      name: "image",
      label: "Image URL",
      options: {
        customBodyRender: (value) => {
          return (
            <div className="flex flex-col flex-wrap overflow-auto w-40">
              {!value ? null : <img src={value} />}
            </div>
          )
        },
      },
    },

    { required: false, name: "description", label: "Description" },
    { required: true, name: "language", label: "Language" },
    {
      required: false,
      name: "location",
      type: "object",
      properties: {
        country: { type: "string", title: "Country" },
        state: { type: "string", title: "State" },
        city: { type: "string", title: "City" },
        street: { type: "string", title: "Street" },
        county: { type: "string", title: "County" },
        zipCode: { type: "string", title: "Zip Code" },
      },
      options: {
        customBodyRender: (value) => {
          return (
            <div className="flex flex-col flex-wrap overflow-auto w-40">
              {!value
                ? null
                : Object.keys(value).map((k) => {
                    return (
                      <label>
                        <b>{k}:</b>
                        {value[k]}
                      </label>
                    )
                  })}
            </div>
          )
        },
      },
    },
    {
      required: false,
      name: "userIds",
      type: "array",
      items: {
        type: "string",
      },
      options: {
        customBodyRender: (value) => {
          return (
            <div className="flex flex-col flex-wrap overflow-auto w-40">
              {!value
                ? null
                : value.map((item, i) => {
                    return (
                      <label key={i}>
                        <b>
                          {i + 1}-{item}
                        </b>
                      </label>
                    )
                  })}
            </div>
          )
        },
      },
    },
  ],

  uiSchema: {
    iconUrl: {
      "ui:widget": "image",
    },
    image: {
      "ui:widget": "image",
    },
    language: {
      "ui:widget": LanguageDropdownWidget,
    },
    location: {
      "ui:widget": LocationCustomWidget,
    },
    userIds: {
      items: {
        "ui:widget": AutocompleteWidget,
      },
    },
    expirationDate: {
      "ui:widget": "datetime",
    },

    description: {
      "ui:widget": "textarea",
    },
  },

  async imageProcessor(fromData, handleUpload, id) {
    if (fromData.iconUrl) {
      fromData.iconUrl = await handleUpload(id, this.IMAGES_BUCKET, fromData.iconUrl)
    }

    if (fromData.image) {
      fromData.image = await handleUpload(id, this.IMAGES_BUCKET, fromData.image)
    }

    return fromData
  },
  widgets: { SelectWidget, image: ImageWidget },
}

const Sponsors = () => {
  return (
    <div>
      <DataProvider {...data}>
        <ListComponent />
      </DataProvider>
    </div>
  )
}
export default Sponsors

/*
import { Box, Button, Container, MenuItem, Select, Typography } from "@mui/material"
import React, { useEffect, useState } from "react"
import { addDoc, collection, deleteDoc, doc, getDocs, updateDoc } from "firebase/firestore"

import { Delete } from "@material-ui/icons"
import { Edit } from "@mui/icons-material";
import LoadingScreen from "react-loading-screen"
import MUIDataTable from "mui-datatables"
import { db } from "../firebase"
import { useNavigate } from "react-router-dom"

const Sponsors = () => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const [userIds, setUserIds] = useState([])
  const [selectedUserId, setSelectedUserId] = useState("")
  const navigate = useNavigate()

  useEffect(() => {
    const fetchData = async () => {
      const querySnapshot = await getDocs(collection(db, "sponsors"))
      const dataList = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
      setData(dataList)
      setLoading(false)
    }

    const fetchUsers = async () => {
      const usersSnapshot = await getDocs(collection(db, "users"))
      const userList = usersSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
      setUserIds(userList)
    }

    fetchData()
    fetchUsers()
  }, [])

  const handleAdd = async () => {
    const newDoc = {
      commentsCount: 0,
      disabled: false,
      iconUrl: "",
      id: "",
      image: "",
      likesCount: 0,
      location: { city: "", country: "", province: "" },
      name: "New Item",
      nameImage: "",
      order: data.length + 1,
      profileUID: "",
      shareCount: 0,
    }

    const docRef = await addDoc(collection(db, "sponsors"), newDoc)
    setData([...data, { id: docRef.id, ...newDoc }])
  }

  const handleDelete = async (id) => {
    await deleteDoc(doc(db, "sponsors", id))
    setData(data.filter((item) => item.id !== id))
  }

  const handleEdit = async (id, updatedData) => {
    await updateDoc(doc(db, "sponsors", id), updatedData)
    setData(data.map((item) => (item.id === id ? { id, ...updatedData } : item)))
  }

  const handleUserChange = (event) => {
    setSelectedUserId(event.target.value)
  }

  const handleRowClick = (rowData, rowMeta) => {
    // const id = data[rowMeta.dataIndex].id
    // navigate(`/sponsors/${id}/edit`) // Navigate to the edit page
  }

  const columns = [
    {
      name: "name",
      label: "Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "location.city",
      label: "City",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "location.country",
      label: "Country",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "likesCount",
      label: "Likes Count",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "shareCount",
      label: "Share Count",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "actions",
      label: "Actions",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const id = data[tableMeta.rowIndex].id
          return (
           <div>
             <Button onClick={() => navigate(`/sponsors/${id}/edit`)} color="secondary" startIcon={<Edit />}>
              Edit
            </Button>
            <Button onClick={() => handleDelete(id)} color="secondary" startIcon={<Delete />}>
              Delete
            </Button>
           </div>
          )
        },
      },
    },
  ]

  const options = {
    filterType: "checkbox",
    onRowClick: handleRowClick, // Set the row click handler
  }

  return (
    <LoadingScreen
      loading={loading}
      bgColor="#f1f1f1"
      spinnerColor="#9ee5f8"
      textColor="#676767"
      text="Loading..."
    >
      <Container>
        <Typography variant="h4" gutterBottom>
          Sponsors
        </Typography>

        <Select
          value={selectedUserId}
          onChange={handleUserChange}
          displayEmpty
          fullWidth
          sx={{ mb: 2 }}
        >
          <MenuItem value="">
            <em>Select User</em>
          </MenuItem>
          {userIds.map((user) => (
            <MenuItem key={user.id} value={user.id}>
              {user.name || user.id}
            </MenuItem>
          ))}
        </Select>

        <Box my={2}>
          <Button variant="contained" color="primary" onClick={handleAdd}>
            Add New Item
          </Button>
        </Box>

        <MUIDataTable title={"Data List"} data={data} columns={columns} options={options} />
      </Container>
    </LoadingScreen>
  )
}

export default Sponsors
*/
