// import {Form, FormEdit, FormGrid, Grid} from '@formio/react';
import React, {useEffect} from 'react';
import {useState} from 'react';
import {getColumns} from './getColumns';
import {getData} from '../core/CreateEditOptions/getData';
import {DataTable} from '../core/CreateEditOptions/DataTable';
import update from '../core/CreateEditOptions/update';
import getMap from './getMap';
import {options} from './options';
import {schema} from './schema';

const PATH = 'app-configurations/translations';
const BUCKET = 'assets';
const TITLE = 'Translations';
const ADD_TITLE = 'Add new Translation';

const Main = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [columns, setColumns] = useState([]);
  useEffect(() => {
    if (!data?.length) {
      getData({setData, path: PATH, getMap, setLoading});
    }
  }, []);

  useEffect(() => {
    if (!data?.length) {
      return;
    }
    setColumns(
      getColumns({
        setLoading,
        setData,
        path: PATH,
        onEditSuccess: async ({result, close, key}) => {
          await update({
            path: PATH,
            key,
            setLoading,
            data: result.formData,
          });

          getData({setData, path: PATH, getMap, setLoading});
          // updateData(data, 'key');
          // data && setData(data);
          close();
        },
      }),
    );
  }, [data]);

  // if (!data || !Array.isArray(data)) {
  //   setData([]);
  // }

  return (
    <DataTable
      isCollection={false}
      path={PATH}
      bucket={BUCKET}
      title={TITLE}
      addTitle={ADD_TITLE}
      setLoading={setLoading}
      setData={setData}
      getMap={getMap}
      loading={loading}
      data={data}
      columns={columns}
      options={options}
      schema={schema}
      onEditSuccess={data => {
        getData({setData, path: PATH, getMap});
      }}
      onAddSuccess={async ({result, close}) => {
        const {key} = result.formData;
        await update({
          path: PATH,
          key,
          setLoading,
          data: result.formData,
        });

        getData({setData, path: PATH, getMap});
        close();
      }}
    />
  );
};

export default Main;
