import React from 'react';
import { FormControlLabel, Switch } from '@mui/material';

export function disabledColumnItem() {
  return {
    name: 'disabled',
    options: {
      filter: false,
      customBodyRender: (value, tableMeta, updateValue) => {
        return (
          <FormControlLabel
            label={value ? 'Yes' : 'No'}
            value={value ? 'Yes' : 'No'}
            control={<Switch
              disabled
              color="primary"
              checked={value}
              value={value ? 'Yes' : 'No'} />}
            onChange={event => {
              const val = event.target.value;
            }} />
        );
      },
    },
  };
}
