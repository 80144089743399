// import {Form, FormEdit, FormGrid, Grid} from '@formio/react';
import React, { useEffect, useState } from "react"
import { addDoc, collection } from "firebase/firestore"
import { auth, db, functions } from "../../firebase"
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  sendPasswordResetEmail,
  signOut,
} from "firebase/auth"

import { DataTable } from "../core/CreateEditOptions/DataTable"
// import FormGroup from "@mui/material/FormGroup"
// import Popup from "reactjs-popup"
import { PopupService } from "../PopupService"
import { UserDetail } from "./UserDetail"
import add from "../core/CreateEditOptions/add"
import { getColumns } from "./getColumns"
import { getData } from "../core/CreateEditOptions/getData"
import getMap from "./getMap"
import { httpsCallable } from "firebase/functions"
import { options } from "./options"
import { schema } from "./schema"
// import update from "../core/CreateEditOptions/update"
import { updateData } from "../core/CreateEditOptions/updateData"

export const updateUser = httpsCallable(functions, "updateUser")

export const PATH = "users"
const BUCKET = "users/assets"
const TITLE = "Users Config"
const ADD_TITLE = "Add new User"
const IMAGE_PROP_NAME = "profileImageUrl"

const Main = () => {
  const [data, setData] = useState()
  const [loading, setLoading] = useState()
  const [columns, setColumns] = useState([])
  const [selectedRowData, setSelectedRowData] = useState(null)

  function filterFunction(data) {
    return !data?.email && !data?.name
  }

  useEffect(() => {
    getData({ setData, path: PATH, getMap, isCollection: true, filterFunction })
  }, [])

  useEffect(() => {
    if (data?.length) {
      const resultColumns = getColumns({
        imagePropName: IMAGE_PROP_NAME,
        data,
        setLoading,
        setData,
        path: PATH,
        onEditSuccess: async ({ result, close, id }) => {
          await updateData({
            path: `${PATH}/${id}`,
            setLoading,
            data: result.formData,
          })

          getData({
            setData,
            path: PATH,
            getMap,
            isCollection: true,
            setLoading,
            filterFunction,
          })
          close()
        },
      })
      setColumns(resultColumns)
    }
  }, [data])

  if (!data || !Array.isArray(data)) {
    setData([])
  }

  return (
    <div>
      <DataTable
        isCollection
        imagePropName={IMAGE_PROP_NAME}
        path={PATH}
        bucket={BUCKET}
        title={TITLE}
        addTitle={ADD_TITLE}
        setLoading={setLoading}
        setData={setData}
        getMap={getMap}
        loading={loading}
        data={data}
        columns={columns}
        options={options}
        schema={schema}
        onRowClicked={(rowData, rowMeta) => {
          const d = data?.[rowMeta.dataIndex]
          console.log("selectedRowData------->", d)
          setSelectedRowData(d)
          PopupService.setPopup(
            <UserDetail
              selectedRowData={d}
              setLoading={setLoading}
              setSelectedRowData={setSelectedRowData}
            />,
          )
          // setOpenDetails(true);
        }} //rowData: string[], rowMeta: { dataIndex: number; rowIndex: number }
        onSuccess={() => {
          getData({ setData, path: PATH, getMap, isCollection: true, setLoading, filterFunction })
        }}
        onEditSuccess={() => {
          getData({ setData, path: PATH, getMap, isCollection: true, setLoading, filterFunction })
        }}
        onAddSuccess={async ({ result, close }) => {
          await add({
            path: PATH,
            setLoading,
            data: result.formData,
          })

          getData({ setData, path: PATH, getMap, isCollection: true, setLoading, filterFunction })
          close()
        }}
      />

      {/* <Popup
      title="User Details"
      open={openDetails}
      onClose={setOpenDetails}
    >
      {close => {
        if (!selectedRowData) {
          return null;
        }
        return <UserDetail close={close} selectedRowData={selectedRowData} setLoading={setLoading} setSelectedRowData={setSelectedRowData} />;
      }}
    </Popup> */}
    </div>
  )
}

export function insertSpaces(str) {
  return str
    .replace(/(_|-)/g, " ")
    .trim()
    .replace(/\w\S*/g, function (str) {
      return str.charAt(0).toUpperCase() + str.substr(1)
    })
    .replace(/([a-z])([A-Z])/g, "$1 $2")
    .replace(/([A-Z])([A-Z][a-z])/g, "$1 $2")
  // string = string.replace(/([a-z])([A-Z])/g, '$1 $2');
  // string = string.replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
  // return string;
}
export const registerWithEmailAndPassword = async (name, email, password) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password)
    const user = res.user
    await addDoc(collection(db, "users"), {
      uid: user.uid,
      name,
      authProvider: "local",
      email,
    })
  } catch (err) {
    console.error(err)
    alert(err.message)
  }
}

export const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email)
    alert("Password reset link sent!")
  } catch (err) {
    console.error(err)
    alert(err.message)
  }
}

export const _sendEmailVerification = async (user) => {
  try {
    await sendEmailVerification(auth)
    alert("Email verification link sent!")
  } catch (err) {
    console.error(err)
    alert(err.message)
  }
}

export const logout = () => {
  signOut(auth)
}

export default Main
