import { Avatar, Button, FormLabel, Tooltip } from "@mui/material"
import { DataProvider, ListComponent } from "../GenericListComponent"
import { DateTimeWidget, ImageWidget } from "./CustomWidgets"
// RaffleTable.js
import React, { useEffect, useState } from "react"

// import { Button } from "@mui/material"
// import { ImageWidget } from "./components/CustomWidgets"
// import LoadingScreen from "react-loading-screen"
// import MUIDataTable from "mui-datatables"
// import { db } from "../firebase"
// import { useNavigate } from "react-router-dom"
import { DateTime } from "luxon"
import { LocationCustomWidget } from "./LocationCustomWidget"
import { PopupService } from "./PopupService"
import { convertToMeasure } from "../utils"
import { useNavigate } from "react-router-dom"

// import { collection, getDocs } from "firebase/firestore"

const ticketDesign = (showEdit) => {
  return {
    name: "ticketDesign",
    type: "object",
    label: "Ticket Design",
    properties: {
      backgroundColor: { type: "string", title: "Background Color" },
      color: { type: "string", title: "Color" },
      height: { type: "number", title: "Height" },
      justifyContent: { type: "string", title: "Justify Content" },
      margin: { type: "number", title: "Margin" },
      padding: { type: "number", title: "Padding" },
      textColor: { type: "string", title: "Text Color" },
      width: { type: "number", title: "Width" },
    },
    options: {
      customBodyRender: (value) => {
        value = value || {
          textColor: "#000",
          color: "#000",
          justifyContent: "center",
          backgroundColor: "#FFF",
          margin: 5,
          image: "",
          height: 120,
          width: 180,
          padding: 5,
        }
        return (
          <div className="flex flex-col flex-wrap overflow-auto w-full h-40 overflow-hidden">
            <div
              onClick={(e) => e.stopPropagation()}
              className="flex flex-col overflow-hidden"
              style={{
                backgroundColor: value.backgroundColor,
                color: value.textColor || value.color,
                height: value.height,
                width: value.width,
                padding: `calc(${convertToMeasure(value.padding)} + 5px)`,
                margin: value.margin,
                justifyContent: value.justifyContent,

                borderWidth: 1,
                borderColor: "#DDD",
                borderRadius: 20,
              }}
            >
              <p>
                <b>TEST 1:</b> testing 1234
              </p>
              <p>
                <b>TEST 2:</b> testing 1234
              </p>
              <p>
                <b>TEST 3:</b> testing 1234
              </p>
            </div>
            {!showEdit ? null : (
              <button
                onClick={(e) => {
                  console.log(e)
                  e.stopPropagation()
                  PopupService.setPopup(
                    <DataProvider {...ticketDesignData}>
                      <ListComponent />
                    </DataProvider>,
                  )
                }}
              >
                Edit
              </button>
            )}
          </div>
        )
      },
    },
  }
}

const ticketDesignData = {
  onRowClick: (list, navigate) => {},
  getListLocation(id) {},

  EDIT_TITLE: "Edit Ticket Design",
  TITLE: "Ticket Design",
  IMAGES_BUCKET: "ticketDesignImages",
  columnsProperties: [ticketDesign(true)],

  uiSchema: {},

  async imageProcessor(fromData, handleUpload, id) {
    if (fromData.images?.length > 0) {
      const imagesURLs = await Promise.all(
        fromData.images.map(({ image, ...rest }) => {
          return handleUpload(id, this.IMAGES_BUCKET, image, rest)
        }),
      )
      fromData.images = imagesURLs
    }

    return fromData
  },
}

const data = {
  widgets:{
    DateTimeWidget
  },
  onRowClick: (list, navigate) => {
    return (_rowData, rowMeta) => {
      const state = list[rowMeta.dataIndex]
      const id = state.id
      navigate(`/raffles/${id}`, { state })
    }
  },
  getListLocation(id) {
    return ["raffles"]
  },

  EDIT_TITLE: "Edit Raffle",
  ADD_TITLE: "Add Raffle",
  LOADING_MESSAGE: "Loading raffles...",
  TITLE: "Raffles",
  IMAGES_BUCKET: "rafflesImages",

  /*
createdBy(string)
createdDate(number)
description(string)
disabled(boolean)
height(number)
id(string)
lastTicketIndex(number)
name(string)
order(number)
points(number)
province(string)
raffleDate(number)
raffleImages(array)-(string)
raffleLocation(map)

subtitle(string)
termsText(string)
ticketDesign(null)
title(string)
type(string)
value(number)
*/
  /*
      disabled: types.optional(types.boolean, false),
      name: types.optional(types.string, ""),
      city: types.optional(types.string, "*"),
      country: types.optional(types.string, "*"),
      province: types.optional(types.string, "*"),
      county: types.optional(types.string, "*"),
      street: types.optional(types.string, "*"),
      info: types.optional(types.string, ""),
      image: types.optional(types.string, ""),
      phone: types.optional(types.string, ""),
      houseNumber: types.optional(types.string, ""),
      postcode: types.optional(types.string, ""),
*/
  columnsProperties: [
    { hidden: true, name: "id", label: "Id" },
    { required: false, name: "disabled", label: "Disabled", type: "boolean" },
    {  hidden: false, required: false, name: "winTicketPerLike", label: "Win Ticket Per Like", type: "boolean" },

    {
      required: true,
      type: "number",
      name: "raffleDate",
      label: "Raffle Date",
      options: {
        customBodyRender: (value) => {
          if (typeof value === "number") {
            return DateTime.fromMillis(value).toLocaleString(DateTime.DATETIME_FULL)
          }
          return value
        },
      },
    },
    { required: true, name: "name", label: "Name" },
    { required: true, type: "number", name: "order", label: "Order" },
    { required: false, type: "string", name: "country", label: "Country" },
    {
      required: false,
      name: "raffleLocation",
      label: "Location Info",
      type: "object",
      properties: {
        disabled: { type: "boolean", title: "Disabled" },
        name: { type: "string", title: "Name" },
        country: { type: "string",title: "Country" },
        province: { type: "string", title: "Province" },
        city: { type: "string", title: "City" },
        street: { type: "string", title: "Street" },
        info: { type: "string", title: "Info" },
        phone: { type: "string", title: "Phone" },
        postcode: { type: "string", title: "Postal Code" },
        houseNumber: { type: "string", title: "Location #" },
      },

      options: {
        customBodyRender: (value) => {
          return (
            <div className="flex flex-col flex-wrap overflow-auto w-40">
              <p>{JSON.stringify(value)}</p>
              <button>Edit</button>
            </div>
          )
        },
      },
    },
    {
      name: "tickets",
      required: false,
      label: "Tickets",
      hideEdit: true,
      options: {
        customBodyRender: (val, e) => {
          const id = e?.rowData?.[0]
          return <GotoGenerateTicketButton id={id} />
        },
      },
    },
    { hidden: true, name: "value", label: "Id" },

    { ...ticketDesign(true) },
  ],

  uiSchema: {
    raffleLocation: {
      "ui:widget": LocationCustomWidget,
    },
    raffleDate: {
      "ui:widget": "DateTimeWidget",
    },

    description: {
      "ui:widget": "textarea",
    },
  },

  async imageProcessor(fromData, handleUpload, id) {
    if (fromData.images?.length > 0) {
      const imagesURLs = await Promise.all(
        fromData.images.map(({ image, ...rest }) => {
          return handleUpload(id, this.IMAGES_BUCKET, image, rest)
        }),
      )
      fromData.images = imagesURLs
    }

    return fromData
  },
}

const Raffles = () => {
  return (
    <DataProvider {...data}>
      <ListComponent />
    </DataProvider>
  )
}

export default Raffles

function GotoGenerateTicketButton({ id }) {
  const navigate = useNavigate()

  return (
    <Button
      variant="contained"
      color="primary"
      onClick={(e) => {
        e.stopPropagation()
        navigate(`/raffles/${id}/generate-tickets`)
      }}
    >
      Generate Ticket
    </Button>
  )
}
