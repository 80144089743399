import { collection, doc, getDoc, getDocs } from "firebase/firestore";

import { LoadingService } from "../../../Loading";
import { db } from "../../../firebase";

const get = async ({ key, setData, map, path, isCollection, setLoading, filterFunction }) => {
  setLoading && setLoading(true);
  LoadingService.setLoading(true, key);
  try {
    if (typeof path === "string") {
      path = path.split("/");
    }

    if (isCollection) {
      const querySnapshot = await getDocs(collection(db, ...path));

      const arr = [];
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        const data = doc.data();
        let addRecord = true;
        if (filterFunction && filterFunction(data)) {
          addRecord = false;
        }
        if (addRecord) {
          arr.push({ ...doc.data(), id: doc.id });
        }
      });
      setData && setData(arr);
      return arr;
    }

    if (key) {
      const querySnapshot = await getDocs(collection(db, ...path, key));

      const data = querySnapshot.data();
      setData && setData(data);
    } else {
      const docRef = doc(db, ...path);

      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const rawData = { ...docSnap.data(), id: docSnap.id };

        const data = map ? map({ item: rawData, path: docSnap.ref.path }) : rawData;

        setData && setData(data);
      }
      return null;
    }
  } catch (error) {
    console.error(`Exception trying to retrieve the ${key}`, error);
  } finally {
    setLoading && setLoading(false);
    setTimeout(() => {
      LoadingService.setLoading(false, key);
    }, 500);
  }
};

export default get;
