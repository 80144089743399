import React, { useState } from "react"

import { DeleteIcon } from "@chakra-ui/icons"
import { Draggable } from "react-beautiful-dnd"
import EditModal from "./EditModal"
import { IconButton } from "@mui/material"
import TextField from "@mui/material/TextField"

const DraggableInput = ({ id, index, component, updateComponent, deleteComponent }) => {
  const [isEditing, setIsEditing] = useState(false)

  const handleDoubleClick = () => {
    setIsEditing(true)
  }

  return (
    <div>
      <Draggable draggableId={id} index={index}>
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            onDoubleClick={handleDoubleClick}
            className="mb-4"
          >
            <TextField label={component.label || "Input Field"} variant="outlined" fullWidth />
            <IconButton onClick={() => deleteComponent(id)} className="ml-2">
              <DeleteIcon />
            </IconButton>
          </div>
        )}
      </Draggable>
      <EditModal
        open={isEditing}
        handleClose={() => setIsEditing(false)}
        component={component}
        updateComponent={updateComponent}
      />
    </div>
  )
}

export default DraggableInput
