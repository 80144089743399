// import {RJSFSchema} from '@rjsf/utils';
import {
  GoogleAuthProvider,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  RecaptchaVerifier,
  getMultiFactorResolver,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
} from "firebase/auth"
import React, { useEffect, useState } from "react"

import { Button } from "@mui/material"
import Form from "@rjsf/material-ui"
import { LoadingService } from "../Loading"
import { Logout } from "@mui/icons-material"
import { UserDetail } from "./Users/UserDetail"
import { auth } from "../firebase"
import { getData } from "./core/CreateEditOptions/getData"
import { logout } from "./Users/Main"
import { useAuthState } from "react-firebase-hooks/auth"
import { useNavigate } from "react-router-dom"
import validator from "@rjsf/validator-ajv8"

const uiSchema = {
  password: {
    "ui:widget": "password",
    "ui:help": "Hint: Make it strong!",
  },
}

const schema = {
  title: "Login",
  type: "object",
  required: ["email", "password"],
  properties: {
    email: { type: "string", title: "Email" },
    password: { type: "string", title: "Password" },
  },
}

export const googleProvider = new GoogleAuthProvider()

onAuthStateChanged(auth, async (user) => {
  if (user) {
    const idTokenResult = await user.getIdTokenResult()
    if (idTokenResult.claims.Admin) {
      console.log("User is an admin")
      // Allow access to the app
    } else {
      console.log("User is not an admin")
      signOut(auth) // Log out non-admin users
      alert("Access denied. Only admins can log in.")
    }
  }
})

const LoginMain = ({ children }) => {
  const [user, loading, error] = useAuthState(auth)

  const [selectedRowData, setSelectedRowData] = useState({})

  useEffect(() => {
    if (loading) {
      return
    }

    if (user) {
      getData({
        setData: (data) => {
          // console.log("getData USER  DATA: ", data)
          setSelectedRowData(data)
        },
        path: `users/${user.uid}`,
      })
    }
  }, [user, loading])

  if (user && !user.isAnonymous) {
    return (
      <div>
        <Button onClick={logout}>
          Logout
          <Logout />
        </Button>
        <UserDetail
          selectedRowData={selectedRowData}
          setLoading={LoadingService.setLoading}
          setSelectedRowData={setSelectedRowData}
        />
      </div>
    )
  }

  return children
}

const LoginWithGoogle = () => {
  const handleGoogleLogin = async () => {
    // const provider = new GoogleAuthProvider()
    // LoadingService.setLoading(true)

    // try {
    //   await signInWithPopup(auth, provider)
    //   // Handle successful login
    // } catch (error) {
    //   console.error("Error signing in with Google", error)
    // } finally {
    //   LoadingService.setLoading(false)
    // }
    try {
      const result = await signInWithPopup(auth, googleProvider)
      console.log("Google user signed in: ", result.user)
    } catch (error) {
      console.error("Google sign-in error: ", error)
    }
  }

  return <Button onClick={handleGoogleLogin}>Login With Google</Button>
}

const Login = () => {
  const [user, loading, error] = useAuthState(auth)
  // const navigate = useNavigate()
  const [selectedRowData, setSelectedRowData] = useState(null)

  useEffect(() => {
    if (loading) {
      return
    }

    if (user) {
      getData({
        setData: (data) => {
          // console.log("getData USER  DATA: ", data)
          setSelectedRowData(data)
        },
        path: `users/${user.uid}`,
      })
    }
  }, [user, loading])

  if (user && !user.isAnonymous) {
    return (
      <div>
        <Button onClick={logout}>
          Logout
          <Logout />
        </Button>
        <UserDetail
          selectedRowData={selectedRowData}
          setLoading={LoadingService.setLoading}
          setSelectedRowData={setSelectedRowData}
        />
      </div>
    )
  }

  return (
    <LoginMain>
      <Form
        schema={schema}
        uiSchema={uiSchema}
        validator={validator}
        onChange={console.log("changed")}
        onSubmit={async (result) => {
          try {
            const { email, password } = result.formData
            const data = await signInWithEmailAndPassword(auth, email, password)
          } catch (error) {
            if (error.code === "auth/multi-factor-auth-required") {
              const recaptchaVerifier = new RecaptchaVerifier(
                "sign-in-button",
                {
                  size: "invisible",
                  callback: function (response) {
                    // reCAPTCHA solved, you can proceed with
                    // phoneAuthProvider.verifyPhoneNumber(...).
                    // onSolvedRecaptcha();

                    // The user is a multi-factor user. Second factor challenge is required.
                    const resolver = getMultiFactorResolver(auth, error)

                    console.log("signInWithEmailAndPassword---Error--resolver->", resolver)

                    const selectedIndex = 0
                    const hint = resolver.hints[selectedIndex]
                    // Ask user which second factor to use.
                    // You can get the masked phone number via resolver.hints[selectedIndex].phoneNumber
                    // You can get the display name via resolver.hints[selectedIndex].displayName
                    if (hint.factorId === PhoneMultiFactorGenerator.FACTOR_ID) {
                      // User selected a phone second factor.
                      // const recaptchaVerifier = new RecaptchaVerifier('recaptcha-container-id', undefined, auth);

                      const phoneInfoOptions = {
                        multiFactorHint: hint,
                        session: resolver.session,
                      }
                      const phoneAuthProvider = new PhoneAuthProvider(auth)
                      phoneAuthProvider
                        .verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)
                        .then(function (verificationId) {
                          // verificationId will be needed for sign-in completion.

                          let verificationCode = prompt(
                            `Enter verification code sent to your phone number ${hint.phoneNumber}`,
                          )

                          const cred = PhoneAuthProvider.credential(
                            verificationId,
                            verificationCode,
                          )
                          const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred)
                          // Complete sign-in. This will also trigger the Auth state listeners.
                          resolver
                            .resolveSignIn(multiFactorAssertion)
                            .then(function (userCredential) {
                              // userCredential will also contain the user, additionalUserInfo, optional
                              // credential (null for email/password) associated with the first factor sign-in.

                              // For example, if the user signed in with Google as a first factor,
                              // userCredential.additionalUserInfo will contain data related to Google
                              // provider that the user signed in with.
                              // - user.credential contains the Google OAuth credential.
                              // - user.credential.accessToken contains the Google OAuth access token.
                              // - user.credential.idToken contains the Google OAuth ID token.
                              console.log("userCredential---->", userCredential)
                            })
                        })
                        .catch((error) => {
                          recaptchaVerifier.clear()
                        })
                    }
                    // ...
                  },
                },
                auth,
              )
            } else if (error.code === "auth/wrong-password") {
              // Handle other errors such as wrong password.
            }
            console.log("signInWithEmailAndPassword---Error--->", error)
          }
        }}
        onError={console.log("errors")}
      />
      <LoginWithGoogle />
    </LoginMain>
  )
}

export default Login
