import {
    MuiThemeProvider,
    StylesProvider,
    ThemeProvider,
    createGenerateClassName,
} from "@material-ui/core/styles"

import React from "react"

const generateClassName = createGenerateClassName({
    seed: "classes-custom-prefix",
  })
  
  export const MuiProvider = (props) => {
    const { children, theme } = props
    return (
      <StylesProvider generateClassName={generateClassName}>
        <MuiThemeProvider theme={theme}>
          <ThemeProvider theme={theme}>{children}</ThemeProvider>
        </MuiThemeProvider>
      </StylesProvider>
    )
  }
  