import { useNavigate, useRouteError } from "react-router-dom";

import { Button } from "@mui/material";
import { Logo } from "./components/Logo";

export default function ErrorPage() {
    const error = useRouteError();
    console.error(error);
    const navigate = useNavigate();

    return (
        <div id="error-page">
            <Logo />
            <h1>Oops!</h1>
            <p>Sorry, an unexpected error has occurred.</p>
            <p>
                <i>{error.statusText || error.message}</i>
            </p>
            <Button onClick={() => navigate('/')} >
                Go Home
            </Button>
        </div>
    );
}


