import { Button } from "@mui/material"
import CreateEdit from "./CreateEdit"
import { PopupService } from "../../PopupService"
import React from "react"

export function editColumn({
  imagePropName,
  path,
  setLoading,
  onEditSuccess,
  schema,
  column2object,
  dataRows,
}) {
  return {
    name: "Edit",
    options: {
      customHeadLabelRender: () => null,
      filter: true,
      sort: false,
      empty: true,
      customBodyRender: (value, tableMeta, updateValue) => {
        return (
          <Button
            variant="text"
            style={{ marginBottom: 10 }}
            onClick={() => {
              PopupService.setPopup(
                <CreateEdit
                  imagePropName={imagePropName}
                  schema={schema}
                  path={path}
                  setLoading={setLoading}
                  data={column2object(tableMeta.rowData)}
                  title="Edit"
                  onSuccess={(result) => {
                    const id = dataRows ? dataRows[tableMeta.rowIndex]?.id : ""
                    onEditSuccess && onEditSuccess({ result, id })
                  }}
                />,
              )
            }}
          >
            Edit
          </Button>
        )
      },
    },
  }
}
