import { AirplaneTicket, Edit } from "@mui/icons-material"
import {
  AppBar,
  Box,
  Button,
  Card,
  CardContent,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from "@mui/material"
import { DataProvider, ListComponent } from "../GenericListComponent"
// RaffleDetail.js
import React, { useEffect, useState } from "react"
import { doc, getDoc } from "firebase/firestore"
import { useNavigate, useParams } from "react-router-dom"

import { DateTime } from "luxon"
import FixTickets from "./FixTickets"
import { ImageWidget } from "./CustomWidgets"
import ReactJson from "react-json-view"
import { SampleImageRenderer } from "../SampleImageRenderer"
import SwipeableViews from "react-swipeable-views"
import Wheel from "./Wheel"
import { db } from "../firebase"

const RaffleDetail = () => {
  const { id } = useParams()
  const [raffle, setRaffle] = useState(null)
  const [value, setValue] = React.useState(0)
  const navigate = useNavigate()
  const theme = useTheme()

  useEffect(() => {
    const fetchRaffle = async () => {
      const docRef = doc(db, "raffles", id)
      const docSnap = await getDoc(docRef)
      if (docSnap.exists()) {
        setRaffle({ id: docSnap.id, ...docSnap.data() })
      }
    }
    fetchRaffle()
  }, [id])

  if (!raffle) return <div>Loading...</div>

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleChangeIndex = (index) => {
    setValue(index)
  }

  return (
    <div>
      <Box sx={{ bgcolor: "background.paper", width: "80vw" }}>
        <AppBar position="static">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
            textColor="inherit"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            <Tab label="Assets" {...a11yProps(0)} />
            <Tab label="Tickets" {...a11yProps(1)} />
            <Tab label="Users" {...a11yProps(2)} />
            <Tab label="Tools" {...a11yProps(3)} />
          </Tabs>
        </AppBar>
        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          <TabPanel value={value} index={0} dir={theme.direction}>
            <DataProvider {...ticketsData} mainId={id}>
              <ListComponent />
            </DataProvider>
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            Tickets
            {!id ? null : <Wheel raffleId={id} />}
          </TabPanel>
          <TabPanel value={value} index={2} dir={theme.direction}>
            Users
          </TabPanel>
          <TabPanel value={value} index={3} dir={theme.direction}>
            Tools
            <FixTickets raffle={raffle} />
          </TabPanel>
        </SwipeableViews>
      </Box>
    </div>
  )
}

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  }
}

export default RaffleDetail

const ticketsData = {
  onRowClick: (list, navigate) => {
    return (_rowData, rowMeta) => {
      // const state = list[rowMeta.dataIndex]
      // const id = state.id
      // navigate(`/raffles/${id}`, { state })
    }
  },
  getListLocation(id) {
    return ["raffles", id, "assets"]
  },

  EDIT_TITLE: "Edit Asset",
  ADD_TITLE: "Add Asset",
  LOADING_MESSAGE: "Loading assets...",
  TITLE: "Assets",
  IMAGES_BUCKET: "assets",

  columnsProperties: [
    { hidden: true, name: "id", label: "Id" },
    { required: false, name: "disabled", label: "Disabled", type: "boolean" },

    {
      required: true,
      name: "expirationDate",
      label: "Expiration Date",
      options: {
        customBodyRender: (value) => {
          if (typeof value === "number") {
            return DateTime.fromMillis(value).toLocaleString(DateTime.DATETIME_FULL)
          }
          return value
        },
      },
    },
    { required: true, name: "name", label: "Name" },
    { required: true, name: "order", label: "Order" },
    {
      name: "src",
      label: "Source",
      options: {
        customBodyRender: SampleImageRenderer({ link: true }),
      },
    },
    { required: true, name: "description", label: "Description" },
  ],

  uiSchema: {
    expirationDate: {
      "ui:widget": "datetime",
    },
    src: {
      "ui:widget": ImageWidget,
    },
    description: {
      "ui:widget": "textarea",
    },
  },

  async imageProcessor(formData, handleUpload, id, mainId) {
    if (formData.src) {
      formData.src = await handleUpload(id, `raffles/${mainId}/${this.IMAGES_BUCKET}`, formData.src)
    }

    return formData
  },
}
