export const column2object = columns => {
  return {
    disabled: !!columns[2],
    name: columns[3] || "",
    profileImageUrl: columns[4] || "",
    phone: columns[5] || "",
    email: columns[6] || "",
    address: columns[7] || "",
  }
};
