import {column2object} from './column2object';
import {schema} from './schema';
import {editColumn} from '../core/CreateEditOptions/editColumn';
import {disabledColumnItem} from '../core/disabledColumnItem';

export function getColumns({
  setLoading,
  setData,
  path,
  onEditSuccess,
  imagePropName,
  tableMeta,
  data,
}) {
  return [
    {
      name: 'path',
      options: {
        display: false,
        viewColumns: false,
      },
    },
    editColumn({
      dataRows: data,
      setData,
      imagePropName,
      path,
      setLoading,
      onEditSuccess,
      schema,
      column2object,
    }),
    disabledColumnItem(),
    'name',
    'background',
    'description',
    {
      name: 'order',
      options: {
        sort: true,
      },
    },
  ];
}
